import { VisibilityOff, Visibility, ArrowBack } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { Formik, Field, FieldInputProps, FieldMetaProps } from "formik";
import React, { useEffect, useState } from "react";
import { Form, useNavigate } from "react-router-dom";

import { passwordValidationSchema } from "@/components/LoginForm/LoginForm.schema";
import { useLoader, useMessager, useNavigation, useSession } from "@/hooks";
import { useChangePasswordMutation, useLoginUserMutation } from "@/service";
import { theme } from "@/theme";

const UpdatePassword: React.FC = () => {
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState<string | null>(null);
  const { setLoader } = useLoader();
  const [
    changePassword,
    { isLoading: updatingPassword, isSuccess: passwordConfirm },
  ] = useChangePasswordMutation();
  const [loginUser, { isLoading: startingSession, isSuccess: loginSuccess }] =
    useLoginUserMutation();
  const handleClickShowPassword = () => setShowPassword(prev => !prev);
  const { setShouldResetPassword } = useNavigation();
  const { setMessage } = useMessager();
  const { sessionUserSt } = useSession();

  const backHandler = () => {
    setShouldResetPassword(false);
    navigation(-1);
  };

  useEffect(() => {
    setLoader(updatingPassword);
    if (passwordConfirm) {
      setMessage({
        title: "Contraseña actualizada",
        body: "Su contraseña ha sido actualizada con éxito",
        open: true,
        buttonCloseTitle: "Entendido",
        type: "modal",
      });
      password && loginUser({ email: sessionUserSt.email, password });
    }
  }, [updatingPassword, passwordConfirm]);

  useEffect(() => {
    setLoader(startingSession);
    loginSuccess && navigation("/dashboard/v1/create-document");
  }, [startingSession, loginSuccess]);

  return (
    <Stack spacing={4}>
      <Typography variant='h6' textAlign='center'>
        Antes de continuar por favor, actualice su contraseña
      </Typography>
      <Formik
        validationSchema={passwordValidationSchema}
        initialValues={{ password: "" }}
        onSubmit={(values, { setSubmitting }) => {
          setPassword(values.password);
          changePassword({
            password: values.password,
            password_confirm: values.password,
          });
          setSubmitting(false);
        }}
      >
        {({ submitForm }) => (
          <Form>
            <Field name='password'>
              {({
                field,
                meta,
              }: {
                field: FieldInputProps<unknown>;
                meta: FieldMetaProps<unknown>;
              }) => (
                <FormControl sx={{ width: "100%" }} variant='outlined'>
                  <InputLabel htmlFor='outlined-adornment-password'>
                    Nueva contraseña
                  </InputLabel>
                  <OutlinedInput
                    {...field}
                    id='outlined-adornment-password'
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {meta.touched && meta.error ? (
                    <Typography variant='body2' color='tomato'>
                      {meta.error}
                    </Typography>
                  ) : null}
                </FormControl>
              )}
            </Field>
            <Button
              variant='contained'
              fullWidth
              size='large'
              disabled={updatingPassword}
              onClick={submitForm}
              sx={{ mt: theme.spacing(2), mb: theme.spacing(2) }}
            >
              <Typography
                variant='body1'
                align='center'
                fontWeight='bold'
                textTransform='capitalize'
              >
                Crear nueva contraseña
              </Typography>
            </Button>
            <Button
              variant='text'
              fullWidth
              size='large'
              disabled={updatingPassword}
              startIcon={<ArrowBack />}
              onClick={backHandler}
            >
              Atras
            </Button>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default UpdatePassword;
