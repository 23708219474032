import { CircularProgress } from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import React from "react";

import { useLoader } from "@/hooks";

import {
  StyledBackDrop,
  StyledContainer,
  StyledLoader,
} from "./GlobalLoader.styles";

const GlobalLoader: React.FC = () => {
  const AnimatedContainer = animated(StyledContainer);
  const { loaderSt } = useLoader();

  const spring = useSpring({
    from: {
      opacity: 0,
      y: "6%",
    },
    to: {
      opacity: 1,
      y: "0",
    },
  });

  if (!loaderSt) {
    return <></>;
  }

  return (
    <AnimatedContainer style={spring} data-testid='global-loader-component'>
      <StyledLoader>
        <CircularProgress size={40} color='primary' />
      </StyledLoader>
      <StyledBackDrop />
    </AnimatedContainer>
  );
};

export default GlobalLoader;
