import * as yup from "yup";

export interface ResetPasswordValues {
  email: string;
}

export const validationSchema = yup.object({
  email: yup
    .string()
    .email("Por favor introduce un correo válido")
    .required("Por favor introduce tu correo electrónico"),
});


export const initialValues: ResetPasswordValues = {
  email: ''
};