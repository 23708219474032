import { VisibilityOutlined, EditOutlined, DeleteOutlined, DriveFileRenameOutline } from "@mui/icons-material";
import {
  IconButton,
  Tooltip
} from "@mui/material";
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { useMessager } from "@/hooks";
import { IUser } from "@/state/User/slice.types";

import { blueIconSx, redIconSx } from "./UsersList.styles";
import SignatureCreditForm from "../SignatureCreditForm";

interface UsersListActionsProps {
  row: IUser;
}

const UsersListActions: FC<UsersListActionsProps> = ({ row }) => {
  const { setMessage } = useMessager();

  const handleOpen = () => {
    setMessage({
      title: "Agregar créditos",
      body: (<SignatureCreditForm user={row} />),
      open: true,
      buttonCloseTitle: "Entendido",
      // onClose: handleClose,
      type: "modal",
    })
  };

  return (
    <>
      <Tooltip title="Ver Detalles">
        <IconButton component={NavLink} to={`/dashboard/v1/admin/manage-users/details/${row.id}`}>
          <VisibilityOutlined sx={blueIconSx} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Editar">
        <IconButton component={NavLink} to={`/dashboard/v1/admin/manage-users/update/${row.id}`}>
          <EditOutlined sx={blueIconSx} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Agregar Créditos">
        <IconButton onClick={handleOpen}>
          <DriveFileRenameOutline sx={blueIconSx} />
        </IconButton>
      </Tooltip>
      {false && (
        <Tooltip title="Eliminar">
          <IconButton>
            <DeleteOutlined sx={redIconSx} />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
};

export default UsersListActions;