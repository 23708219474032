import { Paper, PaperProps, lighten, styled } from "@mui/material";

interface StyledPaperContainerProps extends PaperProps {
  color: string;
}

export const StyledPaperContainer = styled(Paper)<StyledPaperContainerProps>(
  ({ theme, color }) => ({
    padding: theme.spacing(2),
    backgroundColor: color,
    boxShadow: theme.shadows[24],
    // width: "100%",
    cursor: "pointer",
    transition: ".2s ease-in-out",
    "&:hover": {
      backgroundColor: lighten(color, 0.1),
      transform: `translateY(-${theme.spacing(0.5)})`,
    },
  }),
);
