import { Box, BoxProps, alpha, styled } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(() => ({
  height: "100vh",
  width: "100vw",
  position: "absolute",
  zIndex: 999999999,
  top: 0,
  left: 0,
}));

export const StyledLoader = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
}));

export const StyledBackDrop = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100%",
  width: "100%",
  backdropFilter: "blur(2px)",
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  zIndex: 1,
}));
