import { Typography, Box, Grid, Slider, Button, Alert, TextField } from '@mui/material';
import { Formik } from 'formik';
import ArchiveEditOutlineIcon from 'mdi-react/ArchiveEditOutlineIcon';
import { FC, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Permissions } from "@/helpers/permissions";
import { useSignaturePackage, useSession, useMessager, useLoader } from '@/hooks';
import AuthorizedRoute from '@/routes/AuthorizedRoute';
import { useLazyGetAllSignaturePackagesQuery, usePurchaseSignaturePackageMutation } from "@/service";
import { ISignaturePackage } from '@/state/SignaturePackage/slice.types';

import { initialValues, validationSchema, SignaturePackagePurchaseValues } from './SignaturePurchaseForm.schema';
import { signaturePricerSx, containerSx } from './SignaturePurchaseForm.styles';
import { SignatureCreditBar } from '../SignatureCreditBar';
import { SocialFooter } from '../SocialFooter';

const SignaturePurchaseForm: FC = () => {
  const navigate = useNavigate();
  const [getAllSignaturePackages, { isLoading, isSuccess }] = useLazyGetAllSignaturePackagesQuery();
  const [purchaseSignaturePackage, { isSuccess: isPostSuccess, isLoading: isPostLoading }] = usePurchaseSignaturePackageMutation();
  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const { sessionUserSt, sessionSignatureCreditsSelectorSt: signature_credit } = useSession();
  const { signaturePackagesSt } = useSignaturePackage();

  useEffect(() => {
    getAllSignaturePackages();
  }, []);

  const handleOnClose = () => {
    navigate('/dashboard/v1/create-document');
  };

  useEffect(() => {
    setLoader(isPostLoading);
    if (isPostSuccess && !isPostLoading) {
      setMessage({
        title: "Compra realizada con éxito",
        body: (
          <Alert severity="success">
            Se ha realizado la compra éxitosamente. Le llegará un correo eléctronico con un enlace para proceder al pago de la compra del paquete de firmas.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleOnClose,
        type: "modal",
      })
    }
  }, [isPostLoading, isPostSuccess])

  const onSubmit = (values: SignaturePackagePurchaseValues) => {
    const { quantity } = values;
    const currentPackage = signaturePackagesSt.find((pack: ISignaturePackage) => (quantity >= pack.min_quantity && quantity <= pack.max_quantity));
    purchaseSignaturePackage({
      quantity,
      package_id: currentPackage.id,
      user_id: sessionUserSt.id,
    });
  };

  const marks = signaturePackagesSt.map((pack: ISignaturePackage) => ({
    label: pack.name,
    value: pack.max_quantity,
  }));

  const getPackagePriceByQuantity = (quantity: number) => {
    const currentPackage = signaturePackagesSt.find((pack: ISignaturePackage) => (quantity >= pack.min_quantity && quantity <= pack.max_quantity));
    if (currentPackage) {
      return currentPackage.price_per_signature;
    }
    return 0;
  }

  return (
    <AuthorizedRoute allowedPermission={Permissions.PURCHASE_USER_SIGNATURE_CREDITS}>
      <Box sx={containerSx} data-testid='purchase-credits-form-component'>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" color="primary">Comprar Créditos de Firmas</Typography>
          </Grid>
          {(signature_credit) && (
            <Grid item xs={12} md={8} lg={7}>
              <SignatureCreditBar />
            </Grid>
          )}
          <Grid item xs={12} md={8} lg={7}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Typography variant="body1" align="center">Selecciona un monto:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={9} lg={10}>
                          <Slider
                            name="quantity"
                            value={values.quantity}
                            onChange={handleChange}
                            aria-label="input-slider"
                            valueLabelDisplay="auto"
                            min={10}
                            max={5000}
                            disabled={isLoading}
                            marks={marks}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                          <TextField
                            variant="outlined"
                            value={values.quantity}
                            size="small"
                            fullWidth
                            onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue('quantity', event.target.value === '' ? 0 : Number(event.target.value))}
                            onBlur={() => {
                              if (values.quantity < 0) {
                                setFieldValue('quantity', 0);
                              } else if (values.quantity > 5000) {
                                setFieldValue('quantity', 5000);
                              }
                            }}
                            disabled={isLoading}
                            inputProps={{
                              min: 10,
                              max: 5000,
                              type: 'number',
                              'aria-labelledby': 'input-slider',
                            }}
                          />
                        </Grid>
                      </Grid>

                    </Grid>
                    {isSuccess && (
                      <Grid item xs={12}>
                        <Box sx={signaturePricerSx}>
                          <Typography variant="h6" align="center" color="primary">El válor de venta es:</Typography>
                          <Typography variant="body1" align="center">
                            ${(getPackagePriceByQuantity(values.quantity) * values.quantity).toLocaleString('es-ES')} MXN + IVA
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={isLoading || isPostLoading}
                        endIcon={<ArchiveEditOutlineIcon />}
                      >
                        Comprar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <SocialFooter />

          </Grid>
        </Grid>
      </Box>
    </AuthorizedRoute>
  );
};

export default SignaturePurchaseForm;