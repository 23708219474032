import { List } from "@mui/material";
import { FC } from "react";

import SignersListItem from "@/components/SignersListItem";
import useDocument from "@/hooks/useDocument";

import { IDisplaySignersListProps } from "./DisplaySignersList.types";

const DisplaySignersList: FC<IDisplaySignersListProps> = ({ setSignerCallback, setEditSignerCallback, setRemoveSignerCallback }) => {
  const { documentSignersSt } = useDocument();

  const handleModalDisplay = () => {
    setSignerCallback(-1);
    setEditSignerCallback && setEditSignerCallback((prev: boolean) => !prev);
  };

  const handleRemoveSigner = (signer: number) => {
    setSignerCallback(signer);
    setRemoveSignerCallback &&
      setRemoveSignerCallback((prev: boolean) => !prev);
  };

  const handleEditSigner = () => {
    setEditSignerCallback && setEditSignerCallback((prev: boolean) => !prev);
  };

  if (documentSignersSt) {

    return (
      <List>
        {documentSignersSt.map((signer: { email: string | undefined; uuid: string }, index: number) => (
          <SignersListItem
            key={`${signer.email}_${index}`}
            item={signer.uuid ?? ""}
            data-testid={`display-signers-list-component-${index}`}
            primaryText={signer.email}
            onEdit={() => setSignerCallback(index)}
            onDeleteClick={() => handleRemoveSigner(index)}
            onExpandClick={handleEditSigner}
            editable
          />
        ))}
        <SignersListItem
          item='last'
          key='add-new-signer'
          primaryText='Añadir nuevo participante'
          onExpandClick={handleModalDisplay}
          editable={false}
        />
      </List>
    );
  }

  return (
    <SignersListItem
      item='none'
      primaryText='Lorem ipsun'
      editable={false}
      onExpandClick={handleModalDisplay}
    />
  );
};

export default DisplaySignersList;
