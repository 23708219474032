import { SxProps } from "@mui/material";

import paperbg from "@/assets/img/welcome_paper.jpg";

export const formContainerSx: SxProps = () => ({
  flex: 1,
});

export const showroomContainerSx: SxProps = () => ({
  backgroundImage: `url(${paperbg})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  overflow: 'hidden',
});