import { Box, BoxProps, styled, SxProps, Theme } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'grid',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
}));



export const containerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  display: 'grid',
  borderRadius: '10px',
  width: '100%',
  boxShadow: theme.shadows[24],
  minHeight: {
    xs: 360,
    md: 293
  }
});

export const dataGridSx: SxProps<Theme> = (theme) => ({
  borderWidth: 0,
  p: theme.spacing(2),
});