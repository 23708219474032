import { PersonOutlined, DriveFileRenameOutline } from "@mui/icons-material";
import { Typography, Button, Chip, useMediaQuery, Theme, IconButton, Badge, Tooltip, Avatar } from "@mui/material";
import React from "react";

import { useSession } from "@/hooks";
import { theme } from "@/theme";

import {
  StyledContainer,
  StyledInfoContainer,
  avatarSx,
} from "./AvatarContainer.styles";
import { IAvatarContainerProps } from "./AvatarContainer.types";

const AvatarContainer: React.FC<IAvatarContainerProps> = ({
  userName,
  userRole,
  userPicture,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { sessionSignatureCreditsSelectorSt } = useSession();

  return (
    <StyledContainer data-testid='avatar-container-component'>
      {(sessionSignatureCreditsSelectorSt) && (
        <>
          {isDesktop ? (
            <Button variant="contained" startIcon={<DriveFileRenameOutline />}>
              Mís Créditos
              <Chip
                label={sessionSignatureCreditsSelectorSt?.remaining_quantity}
                size="small"
                sx={{ ml: 1, backgroundColor: '#ffffff' }}
              />
            </Button>
          ) : (
            <Badge color="secondary" badgeContent={sessionSignatureCreditsSelectorSt?.remaining_quantity} max={999}>
              <Tooltip title="Mís Créditos">
                <IconButton color="primary">
                  <DriveFileRenameOutline />
                </IconButton>
              </Tooltip>
            </Badge>
          )}
        </>
      )}
      <StyledInfoContainer>
        <Typography variant='h6' lineHeight={1} fontSize={14}>
          {userName}
        </Typography>
        <Typography
          variant='body2'
          fontSize={14}
          color={theme.palette.grey[500]}
        >
          {userRole}
        </Typography>
      </StyledInfoContainer>
      {userPicture ? (
        <Avatar alt={userName} src={userPicture} sx={avatarSx} />
      ) : (
        <Avatar sx={avatarSx}>
          <PersonOutlined color="primary" />
        </Avatar>
      )}
    </StyledContainer>
  );
};

export default AvatarContainer;
