import { Box, Typography } from "@mui/material";
import React from "react";

import { theme } from "@/theme";

import { IFormErrorMessage } from "./FieldErrorMessage.types";

const FieldErrorMessage: React.FC<IFormErrorMessage> = ({ children }) => {
  return (
    <Box
      paddingTop={theme.spacing(1)}
      data-testid='field-error-message-component'
    >
      <Typography
        variant='body2'
        fontSize={theme.spacing(1.5)}
        color='error'
        align='left'
      >
        {children}
      </Typography>
    </Box>
  );
};

export default FieldErrorMessage;
