import { ChevronRight, Schedule } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Stack,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { FC } from 'react';

import { Document, IProgressStatus, IProgressStatusProps } from './DocumentDetailData.types'

interface DocumentDetailBreadcrumbsProps {
  document: Document;
}

const ProgressStatus: FC<IProgressStatusProps> = ({ progress, status }) => {
  const theme = useTheme();

  const transformStatus = () => {
    if (status) {
      switch (status) {
        case "progress":
          return "En progreso";
        case "pending":
          return "Por realizar";
        case "complete":
          return "Completado";
      }
    }
  };

  const transformStatusColor = () => {
    if (status) {
      switch (status) {
        case "progress":
          return theme.palette.autumn?.main;
        case "pending":
          return theme.palette.secondary.main;
        case "complete":
          return theme.palette.success.light;
      }
    }
  };

  const transformStatusBgColor = () => {
    if (status) {
      switch (status) {
        case "progress":
          return theme.palette.autumn?.main;
        case "pending":
          return theme.palette.grey[400];
        case "complete":
          return theme.palette.success.light;
      }
    }
  };

  return (
    <Stack direction='row' spacing={1}>
      <Avatar
        variant="rounded"
        sx={{
          backgroundColor: alpha(
            transformStatusBgColor() ?? theme.palette.grey[400],
            0.4,
          ),
        }}
      >
        <Schedule width={20} sx={{ fill: transformStatusColor() }} />
      </Avatar>
      <Box>
        <Typography component="p" variant="caption" fontWeight="bold">
          {progress}
        </Typography>
        <Typography variant="caption" color={transformStatusColor()}>
          {transformStatus()}
        </Typography>
      </Box>
    </Stack>
  );
};

const DocumentDetailBreadcrumbs: FC<DocumentDetailBreadcrumbsProps> = ({ document }) => {
  const { status: document_status, payment_status } = document;

  const validateStatus = ({ progress }: IProgressStatus): IProgressStatusProps["status"] => {
    let status;

    if (progress === "payment") {
      if ((document_status === "SIGNED_PENDING" && payment_status === "PAIDOUT") || document_status === "SIGNED") {
        status = "complete";
      } else {
        status = "pending";
      }
    }

    if (progress === "signature") {
      if (document_status === "SIGNED" && payment_status === "PAIDOUT") {
        status = "complete";
      } else {
        status = "pending";
      }
    }

    if (progress === "handed") {
      if (document_status === "SIGNED" && payment_status === "PAIDOUT") {
        status = "complete";
      } else {
        status = "pending";
      }
    }

    return status;
  };

  const crums = [
    <ProgressStatus
      progress='Pago'
      status={validateStatus({
        progress: "payment",
      })}
    />,
    <ProgressStatus
      progress='Firma'
      status={validateStatus({
        progress: "signature",
      })}
    />,
    <ProgressStatus
      progress='Entrega'
      status={validateStatus({
        progress: "handed",
      })}
    />,
  ];

  return (
    <Stack spacing={2} justifyContent='center' alignItems='center'>
      <Breadcrumbs separator={<ChevronRight />} aria-label='breadcrumb'>
        {crums}
      </Breadcrumbs>
    </Stack>
  );
};

export default DocumentDetailBreadcrumbs;