import { Menu } from "@mui/icons-material";
import { AppBar, Box, Toolbar, useMediaQuery, Theme, IconButton, Drawer } from "@mui/material";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "@/components/Header";
import NavigationMenu from "@/components/NavigationMenu";

import { appBarSx, navSx, drawerSx, mainSx, menuButtonSx, navigationMenuSx } from "./Layout.styles";
import { ILayoutProps } from "./Layout.types";

const Layout: FC<ILayoutProps> = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <AppBar
        position="fixed"
        color="default"
        elevation={3}
        sx={appBarSx}>
        <Toolbar>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={isDesktop ? 'flex-end' : 'space-between'}
            alignItems="center"
            width="100%">
            {!isDesktop && (
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={menuButtonSx}>
                <Menu color="primary" />
              </IconButton>
            )}
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <Header />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={navSx}
        aria-label="mailbox folders"
      >
        {isDesktop ? (
          <Drawer
            variant="permanent"
            sx={drawerSx}
            open
          >
            <Box sx={navigationMenuSx}>
              <NavigationMenu />
            </Box>
          </Drawer>
        ) : (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={drawerSx}
          >
            <Box sx={navigationMenuSx}>
              <NavigationMenu onClose={handleDrawerClose} />
            </Box>
          </Drawer>
        )}
      </Box>
      <Box
        component="main"
        sx={mainSx}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
