import { SxProps, Theme } from '@mui/material';

export const boxContaineSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "10px",
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
});

export const titleSx: SxProps<Theme> = (theme) => ({
  color: theme.palette.primary.main,
  mb: 2,
});

export const backButtonSx: SxProps<Theme> = (theme) => ({
  color: theme.palette.primary.main,
  border: `solid 1px ${theme.palette.primary.light}`,
  mr: 3,
});

export const listSx: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(0),
});