import { useDispatch, useSelector } from "react-redux";

import { rolesSelector } from "@/state/Role/selectors";
import {
  setRoles as setRolesAction,
} from "@/state/Role/slice";
import { IRole } from "@/state/Role/slice.types";


const useRole = () => {
  const dispatch = useDispatch();
  const rolesSt = useSelector(rolesSelector);

  const setRoles = (roles: IRole[]) => dispatch(setRolesAction(roles));

  return {
    rolesSt,
    setRoles
  };
};

export default useRole;
