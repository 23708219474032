import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Button,
  Box,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { useMessager } from "@/hooks";
import {
  useLoginUserMutation,
} from "@/service";

import { ILogin, initialValues, validationSchema } from "./LoginForm.schema";
import { anchorTextSx, submitButtonSx } from "./LoginForm.styles";

const LoginForm: React.FC = () => {
  const { setMessage } = useMessager();
  const [showPassword, setShowPassword] = useState<boolean>();
  const [loginUser, { isSuccess: loginSuccess, data, isLoading: loginLoading }] = useLoginUserMutation();
  const navigation = useNavigate();

  const handleShowPassword = () => setShowPassword(show => !show);

  const checkVerifyPassword = (passwordReset: boolean) => {
    if (passwordReset) {
      navigation("/session/update-password");
    } else {
      navigation("/dashboard/v1/create-document");
    }
  };

  useEffect(() => {
    if (loginSuccess) {
      if (data.data.error) {
        setMessage({
          title: "Usuario o contraseña incorrectos",
          body: "El usuario o la contraseña proporcionados son incorrectos, por favor verifique sus credenciales.",
          open: true,
          buttonCloseTitle: "Volver a intentarlo",
          type: "modal",
        });
      } else {
        checkVerifyPassword(data.data.password_reset);
      }
    }
  }, [loginSuccess]);

  const onSubmit = (values: ILogin) => {
    loginUser(values);
  };

  return (
    <Box component="main">
      <Formik
        data-testid="login-form-component"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleSubmit, values, errors, touched, handleChange, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  label="Correo electrónico"
                  placeholder="Escribe..."
                  variant="outlined"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  label="Contraseña"
                  placeholder="Mínimo 7 caracteres"
                  variant="outlined"
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
                <Typography align="right" sx={anchorTextSx}>
                  <Typography
                    component={NavLink}
                    to="/session/reset-password"
                    variant="body2"
                    fontWeight="bold"
                    align="right"
                    color="primary"
                  >
                    ¿Has olvidado tu contraseña?
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  disabled={loginLoading || !dirty}
                  sx={submitButtonSx}
                  type="submit"
                >
                  Continuar
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center" sx={anchorTextSx}>
                  ¿Nuevo en nuestra plataforma?{" "}
                  <Typography component={NavLink} to="/session/signup" variant="body2" fontWeight="bold" color="primary">
                    Crea una cuenta
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box >
  );
};

export default LoginForm;
