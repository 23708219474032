import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import {
  IconButton,
  Tooltip
} from "@mui/material";
import { FC } from 'react';

import { useMessager } from "@/hooks";
import { ISignatureInventory } from "@/state/SignatureInventory/slice.types";

import { blueIconSx, redIconSx } from "./SignatureInventoryList.styles";
import { UpdateSignatureInventoryForm } from "../SignatureInventoryForm";

interface SignatureInventoryListActionsProps {
  row: ISignatureInventory;
}

const SignatureInventoryListActions: FC<SignatureInventoryListActionsProps> = ({ row }) => {
  const { setMessage } = useMessager();

  const handleOpen = (mode: string) => {
    setMessage({
      title: (mode === 'increment') ? "Agregar firmas" : "Remover firmas",
      body: (<UpdateSignatureInventoryForm item={row} mode={mode} />),
      open: true,
      buttonCloseTitle: "Entendido",
      // onClose: handleClose,
      type: "modal",
    })
  };

  return (
    <>
      <Tooltip title="Agregar Firmas">
          <IconButton aria-label="Agregar Firmas" onClick={() => handleOpen('increment')}>
            <EditOutlined sx={blueIconSx} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remover Firmas">
          <IconButton aria-label="Remover Firmas" onClick={() => handleOpen('decrement')}>
            <DeleteOutlined sx={redIconSx} />
          </IconButton>
        </Tooltip>
    </>
  )
};

export default SignatureInventoryListActions;