import { Box, BoxProps, styled, SxProps, Theme } from "@mui/material";

interface IStatusBadgeProps extends BoxProps {
  verified: boolean;
}

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
}));

export const StyledStatusBadge = styled(Box)<IStatusBadgeProps>(
  ({ theme, verified }) => ({
    position: "absolute",
    width: 30,
    height: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 9999,
    top: "50%",
    left: 0,
    transform: "translate(-50%, -50%)",
    backgroundColor: verified
      ? theme.palette.grass?.main
      : theme.palette.autumn?.light,
  }),
);


export const containerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: '10px',
  boxShadow: theme.shadows[24],
});

export const actionButtonIconSx: SxProps<Theme> = (theme) => ({
  fill: theme.palette.atmosphere?.main
});

export const listSubheaderSx: SxProps = () => ({
  px: 0,
  fontWeight: 'bold',
  lineHeight: '16px'
});

export const alertSx: SxProps<Theme> = (theme) => ({
  width: '100%',
  alignItems: 'center',
  '&.MuiAlert-standard.MuiAlert-standardSuccess': {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.success?.main,
  },
  '&.MuiAlert-standard.MuiAlert-standardInfo': {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.info?.main,
  },
});