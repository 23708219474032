import * as yup from "yup";

export interface ILogin {
  email: string;
  password: string;
}

export const validationSchema = yup.object({
  email: yup
    .string()
    .email("Por favor introduce un correo válido")
    .required("Por favor introduce tu correo electrónico"),
  password: yup
    .string()
    .min(7, "La contraseña debe tener al menos 7 caracteres")
    .max(16, "La contraseña debe tener menos de 16 caracteres")
    .required("Por favor introduce tu contraseña"),
});

export const passwordValidationSchema = yup.object({
  password: yup.string().min(8).max(16).required(),
});


export const initialValues: ILogin = {
  email: '',
  password: '',
}