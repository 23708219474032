import {
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import UsersListActions from "./UsersListActions";


export const colDefs: GridColDef[] = [
  {
    field: "actions",
    headerName: "Acciones",
    width: 200,
    renderCell: ({ row }) => (<UsersListActions row={row} />),
  },
  {
    field: "rfc",
    headerName: "RFC",
    width: 200,
    renderCell: ({ value }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: "first_name",
    headerName: "Nombres",
    width: 200,
    renderCell: ({ value }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: "last_name",
    headerName: "Apellidos",
    width: 200,
    renderCell: ({ row }) => <Typography variant="body1">{`${row.last_name} ${row.mother_last_name}`}</Typography>,
  },
  {
    field: "email",
    headerName: "Correo electrónico",
    width: 300,
    renderCell: ({ value }) => (<Typography variant="body1">{value}</Typography>),
  },
  {
    field: "role",
    headerName: "Rol",
    width: 200,
    renderCell: ({ row }) => (<Typography variant="body1">{row.role.name}</Typography>),
  },
  {
    field: "remaining_credits",
    headerName: "Créditos Restantes",
    width: 200,
    renderCell: ({ row }) => (<Typography variant="body1">{row.signature_credit?.remaining_quantity}</Typography>),
  },
];