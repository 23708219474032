import { Box, Grid } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";

import PDFViewer from "@/components/PDFViewer";
import useDocument from "@/hooks/useDocument";

const UploadDocument: FC = () => {
  const { documentBase64St } = useDocument();

  return (
    <Box data-testid='upload-document-component'>
      {(documentBase64St) ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6}>
            <PDFViewer base64={documentBase64St as string} />
          </Grid>
          <Grid item xs={12} md={4} lg={6}>
            <Outlet />
          </Grid>
        </Grid>
      ) : (
        <Outlet />
      )}
    </Box>
  );
};

export default UploadDocument;
