import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/store";

const selfState = (state: RootState) => state.session;
export const userSelector = createSelector(selfState, state => state.user);
export const signatureCreditsSelector = createSelector(selfState, state => state.signature_credit);
export const tokenSelector = createSelector(selfState, state => state.token);
export const roleSelector = createSelector(selfState, state => state.role);
export const permissionsSelector = createSelector(selfState, state => state.permissions);
