import * as yup from "yup";

import { ISignatureCredit } from "@/service/service.types";

export const validationSchema = yup.object({
  quantity: yup
    .number()
    .min(1)
    .required("Por favor selecciona una cantidad de créditos."),
});


export const initialValues: ISignatureCredit = {
  user_id: 0,
  quantity: 0,
}