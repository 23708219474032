import { SxProps, Theme, styled, Chip, emphasize } from '@mui/material';


export const boxContainerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "10px",
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
});

export const backButtonSx: SxProps<Theme> = (theme) => ({
  color: theme.palette.primary.main,
  border: `solid 1px ${theme.palette.primary.light}`,
  mr: 3,
});

export const listSx: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(1),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: '10px',
});

export const titleSx: SxProps<Theme> = (theme) => ({
  color: theme.palette.primary.main,
  py: theme.spacing(2)
});

export const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: theme.spacing(3),
  boxShadow: theme.shadows[1],
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover, &:focus': {
    backgroundColor: emphasize(theme.palette.grey[100], 0.06),
  },
  '&:active': {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.common.white,
  },
})) as typeof Chip;