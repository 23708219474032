import { StyleSheet } from "@react-pdf/renderer";

export const usePDFStyles = StyleSheet.create({
  customPDFPage: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  customPDFView: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  customText: {
    fontSize: 12,
    textAlign: "center",
  },
  customText2: {
    marginTop: 4,
    fontSize: 10,
    textAlign: "center",
  },
});
