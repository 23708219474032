import { Box } from '@mui/material';
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect } from 'react';

import { Permissions } from "@/helpers/permissions";
import { useSignatureInventory, useLoader } from '@/hooks';
import AuthorizedRoute from '@/routes/AuthorizedRoute';
import { useLazyGetAllInventoryQuery } from '@/service';

import { colDefs } from './SignatureInventoryList.map';
import { boxContainerSx, dataGridSx } from './SignatureInventoryList.styles';
import SignatureInventoryListToolbar from './SignatureInventoryListToolbar';
import DataGridEmpty from '../DataGridEmpty';

const SignatureInventoryList: FC = () => {
  const apiRef = useGridApiRef();

  const [getAllInventory, { isLoading }] = useLazyGetAllInventoryQuery();
  const { signatureInventorySt } = useSignatureInventory();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllInventory();
  }, []);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <AuthorizedRoute allowedPermission={Permissions.LIST_SIGNATURE_INVENTORY}>
      <Box sx={boxContainerSx} data-testid="signature-inventory-list-component">
        <DataGrid
          apiRef={apiRef}
          columns={colDefs}
          rows={signatureInventorySt ?? []}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick
          loading={isLoading}
          sx={dataGridSx}
          slots={{
            toolbar: SignatureInventoryListToolbar,
            noRowsOverlay: DataGridEmpty
          }}
          localeText={{
            filterOperatorContains: "Contiene",
            filterOperatorEquals: "Igual",
            filterOperatorStartsWith: "Comienza con",
            filterOperatorEndsWith: "Termina con",
            filterOperatorIsEmpty: "Está vacío",
            filterOperatorIsNotEmpty: "No esta vacío",
            filterOperatorIsAnyOf: "Es cualquiera de",
            filterPanelOperator: "Criterio",
            filterPanelColumns: "Columnas",
            filterPanelInputLabel: "Valor",
            filterPanelInputPlaceholder: "Ingresa un valor",
            columnMenuSortAsc: "Ordenar ASC",
            columnMenuSortDesc: "Ordenar DESC",
            columnMenuHideColumn: "Ocultar columna",
            columnMenuManageColumns: "Gestionar columnas",
            columnMenuFilter: "Filtrar",
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
        />
      </Box>
    </AuthorizedRoute>
  );
};

export default SignatureInventoryList;