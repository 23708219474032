import { Button, Typography, Stack, Box } from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useDocument } from "@/hooks";
import { theme } from "@/theme";

import { StyledContainer, containerSx } from "./DocumentReadyNotification.styles";

const DocumentReadyNotification: React.FC = () => {
  const AnimatedContainer = animated(StyledContainer);
  const { clearDocumentSt } = useDocument();
  const navigation = useNavigate();

  useEffect(() => {
    clearDocumentSt();
  }, []);

  const spring = useSpring({
    from: { opacity: 0, display: "none", transform: "translate3d(100%,0,0)" },
    to: { opacity: 1, display: "flex", transform: "translate3d(0,0,0)" },
  });

  const handleBackButton = () => {
    navigation("/dashboard/v1/create-document");
  };

  return (
    <AnimatedContainer style={spring} data-testid='document-ready-notification-component'>
      <Box sx={containerSx}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography variant="h4" align="center" color="primary">
            ¡Felicitaciones! Ya tienes tu documento listo para firmar.
          </Typography>
          <Button
            variant="contained"
            onClick={handleBackButton}
            sx={{ width: theme.spacing(20) }}
          >
            Volver
          </Button>
        </Stack>
      </Box>
    </AnimatedContainer>
  );
};

export default DocumentReadyNotification;
