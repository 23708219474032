declare module "@mui/material/styles" {
  interface Palette {
    autumn?: Palette["primary"];
    grass?: Palette["primary"];
    pavement?: Palette["primary"];
    almostwhite?: Palette["primary"];
    pimp?: Palette["primary"];
    atmosphere?: Palette["primary"];
  }
  interface PaletteOptions {
    autumn?: Palette["primary"];
    grass?: Palette["primary"];
    pavement?: Palette["primary"];
    almostwhite?: Palette["primary"];
    pimp?: Palette["primary"];
    atmosphere?: Palette["primary"];
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverride {
    autumn: true;
    grass: true;
    pavement: true;
    almostwhite: true;
    pimp: true;
    atmosphere: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    autumn: true;
    grass: true;
    pavement: true;
    almostwhite: true;
    pimp: true;
    atmosphere: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    autumn: true;
    grass: true;
    pavement: true;
    almostwhite: true;
    pimp: true;
    atmosphere: true;
  }
}

declare module "@mui/material/ToggleButton" {
  interface ToggleButtonPropsColorOverrides {
    autumn: true;
    grass: true;
    pavement: true;
    almostwhite: true;
    pimp: true;
    atmosphere: true;
  }
}

declare module "@mui/icons-material" {
  interface SvgIconPropsColorOverrides {
    autumn: true;
    grass: true;
    pavement: true;
    almostwhite: true;
    pimp: true;
    atmosphere: true;
  }
}

export enum EThemeCustomization {
  SPACING_BASE = 8,
  BORDER_RAIDOUS_FULL = 9999,
}

export enum EColors {
  AUTUMN = "#fa8724",
  GRASS = "#5cc858",
  PAVEMENT = "#b4bacc",
  PIMP = "#7239ea",
  ALMOSTWHITE = "#F2F5F8",
  ATMOSPHERE = "#00ADEA",
}
