import { useDispatch, useSelector } from "react-redux";

import { loaderSelector } from "@/state/Loader/selectors";
import { setLoader as setLoaderAction } from "@/state/Loader/slice";

const useLoader = () => {
  const dispatch = useDispatch();
  const loaderSt = useSelector(loaderSelector);

  const setLoader = (open: boolean) => dispatch(setLoaderAction(open));

  return {
    loaderSt,
    setLoader,
  };
};

export default useLoader;
