import { DeleteOutlined, EditOutlined, AccountCircleOutlined, AddBoxOutlined } from "@mui/icons-material";
import {
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Stack,
  Typography,
  Button,
  Box,
  useMediaQuery,
  Theme,
  Snackbar
} from "@mui/material";
import React, { useState } from "react";

import useDocument from "@/hooks/useDocument";

import { iconSx, primaryTextSx, listItemSx, listItemIconSx } from "./SignersListItem.styles";
import { ISignersListItemProps } from "./SignersListItem.types";

const SignersListItem: React.FC<ISignersListItemProps> = ({
  item,
  editable,
  primaryText,
  onEdit,
  onExpandClick,
  onDeleteClick,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [remove, setRemove] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const { removeSignerFromDocument } = useDocument();

  const handleSignerUpdate = (e: unknown) => {
    onEdit && onEdit(e);
    onExpandClick && onExpandClick(e);
  };

  const handleSignerDeletion = (e: unknown) => {
    onEdit && onEdit(e);
    setRemove(prev => !prev);
    onDeleteClick && onDeleteClick(e);
    (!isDesktop) && setOpen(!open);
  };

  const deleteSigner = (uuid: string) => {
    removeSignerFromDocument(uuid);
  };

  return (
    <>
      <ListItem
        sx={listItemSx}
        secondaryAction={(editable) ? (
          <Stack direction="row" flexWrap="wrap" useFlexGap>
            {!remove ? (
              <IconButton size={isDesktop ? 'medium' : 'small'} color="secondary" onClick={handleSignerDeletion}>
                <DeleteOutlined fontSize={isDesktop ? 'medium' : 'small'} />
              </IconButton>
            ) : (
              <>
                {isDesktop && (
                  <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
                    <Typography variant='body2' fontWeight='bold'>
                      ¿Eliminar participante?
                    </Typography>
                    <Button
                      onClick={() => deleteSigner(item)}
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      SI
                    </Button>
                    <Button
                      onClick={handleSignerDeletion}
                      variant="contained"
                      color="almostwhite"
                      size="small"
                    >
                      NO
                    </Button>
                  </Box>
                )}
              </>
            )}
            {!remove && (
              <IconButton size={isDesktop ? 'medium' : 'small'} color="atmosphere" onClick={handleSignerUpdate} disabled={remove}>
                <EditOutlined fontSize={isDesktop ? 'medium' : 'small'} />
              </IconButton>
            )}
          </Stack>
        ) : (
          <IconButton size={isDesktop ? 'medium' : 'small'} color="atmosphere" onClick={e => onExpandClick && onExpandClick(e)}>
            <AddBoxOutlined fontSize={isDesktop ? 'medium' : 'small'} />
          </IconButton>
        )
        }
      >
        {editable && (
          <ListItemIcon sx={listItemIconSx}>
            <AccountCircleOutlined fontSize={isDesktop ? 'medium' : 'small'} sx={iconSx} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={primaryText}
          placeholder="Agregar participante"
          primaryTypographyProps={{
            noWrap: true,
            sx: primaryTextSx
          }}
        />
        {(!isDesktop) && (
          <Snackbar
            open={open}
            autoHideDuration={20000}
            onClose={handleSignerDeletion}
            message="¿Eliminar participante?"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            action={(
              <Stack direction="row" spacing={1}>
                <Button
                  onClick={() => deleteSigner(item)}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  SI
                </Button>
                <Button
                  onClick={handleSignerDeletion}
                  variant="contained"
                  color="almostwhite"
                  size="small"
                >
                  NO
                </Button>
              </Stack>
            )}
          />
        )}
      </ListItem>
    </>
  );
};

export default SignersListItem;
