import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INavigationState } from "./slice.types";

const initialState: INavigationState = {
  activeSession: false,
  resetPassword: null,
};

export const navigationSlice = createSlice({
  initialState,
  name: "navigation",
  reducers: {
    setNavigationHistory: (
      state: INavigationState,
      { payload }: PayloadAction<INavigationState>,
    ) => {
      state.activeSession = payload.activeSession;
    },
    setShouldResetPassword: (
      state: INavigationState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.resetPassword = payload;
    },
  },
});

export const { setNavigationHistory, setShouldResetPassword } =
  navigationSlice.actions;
