import { Box, BoxProps, styled, SxProps } from "@mui/material";

import { EThemeCustomization } from "theme/theme.types";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: theme.spacing(2),
}));

export const StyledInfoContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
}));

export const StyledPictureContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[400],
  borderRadius: EThemeCustomization.BORDER_RAIDOUS_FULL,
  // padding: theme.spacing(1),
}));

export const avatarSx: SxProps = () => ({
  width: 36, 
  height: 36,
});