import { LogoutOutlined } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Box,
  ListItemIcon,
  Alert,
} from "@mui/material";
import { FC, createRef, useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "@/assets/logos/fv-logo-reg.svg";
import NavigationMenuItem from "@/components/NavigationMenuItem/NavigationMenuItem";
import { useMessager, useNavigation, useSession } from "@/hooks";
import { IRoleEnum } from "@/state/Session/slice.types";
import { theme } from "@/theme";

import { userMenuItems, adminMenuItems } from "./NavigationMenu.map";
import { StyledContainer } from "./NavigationMenu.styles";
import { INavigationMenuMap } from "./NavigationMenu.types";
import UserSettings from "../UserSettings";

interface NavigationMenuProps {
  onClose?: () => void;
}

const NavigationMenu: FC<NavigationMenuProps> = ({ onClose }) => {
  const navigation = useNavigate();
  const { setNavigationHistory } = useNavigation();
  const { endSession, canUse, sessionRoleSt } = useSession();
  const { setMessage } = useMessager();
  const [openMenuId, setOpenMenuId] = useState<null | string>(null);
  const [menuItems, setMenuItems] = useState<INavigationMenuMap[]>([]);

  useEffect(() => {
    if (sessionRoleSt === IRoleEnum.USER) {
      setMenuItems(userMenuItems);
    }
    if (sessionRoleSt === IRoleEnum.ADMIN) {
      setMenuItems(adminMenuItems);
    }
  }, [sessionRoleSt, menuItems]);

  const refs = menuItems.reduce(
    (acc, item) => {
      acc[item.id] = createRef<HTMLDivElement>();
      return acc;
    },
    {} as Record<string, React.RefObject<HTMLDivElement>>,
  );

  const handleExtraButtonClick = () => {
    navigation("user-settings/update");
    (onClose) && onClose();
  }

  const handleItemClick = (id: string) => {
    switch (id) {
      case "user-settings":
        setMessage({
          title: "Información de perfil",
          body: <UserSettings />,
          buttonCloseTitle: "Cerrar",
          open: true,
          type: "modal",
          extraButton: true,
          extraButtonTitle: "Editar información",
          handleExtraButtonClick: () => handleExtraButtonClick(),
        });
        break;
      default:
        setOpenMenuId(openMenuId !== id ? id : null);
        break;
    }
  };

  const handleSessionClose = () => {
    setNavigationHistory(false);
    endSession();
    navigation("/session/login");
  };

  const handleEndSession = () => {
    setMessage({
      title: "¿Deseas terminar tu sesión?",
      body: (
        <Alert severity='warning'>
          Si estas creando un contrato, ten en cuenta que perderá el progreso
          realizado.
        </Alert>
      ),
      buttonCloseTitle: "Si",
      open: true,
      type: "modal",
      extraButton: true,
      extraButtonTitle: "No",
      onClose: () => handleSessionClose(),
    });
  };

  const handleNavigation = (path: string) => {
    navigation(path);
    (onClose) && onClose();
  };

  return (
    <StyledContainer
      component='nav'
      aria-labelledby='nested-list-subheader'
      data-testid='navigation-menu-component'
    >
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="center" mb="1rem">
          <Logo height={30} width={200} />
        </Box>

        {menuItems.map(menu => (
          <Box key={menu.id}>
            {canUse(menu.permission) && (
              <>
                <NavigationMenuItem
                  ref={refs[menu.id]}
                  primaryText={menu.text}
                  icon={menu.icon}
                  onClick={() =>
                    menu.link
                      ? handleNavigation(menu.link)
                      : handleItemClick(menu.id)
                  }
                  expanded={openMenuId === menu.id}
                  showExpandIcon={Boolean(menu.subMenu.length)}
                />
                <Collapse in={openMenuId === menu.id} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {menu.subMenu.map(submenu => (
                      <Fragment key={submenu.id}>
                        {canUse(submenu.permission) && (
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => {
                              navigation(submenu?.to);
                              (onClose) && onClose();
                            }}
                          >
                            <ListItemText
                              primary={submenu.text}
                              primaryTypographyProps={{
                                color: theme.palette.grey[700],
                              }}
                            />
                          </ListItemButton>
                        )}
                      </Fragment>
                    ))}
                  </List>
                </Collapse>
              </>
            )}
          </Box>
        ))}
      </Box>
      <List component='div' disablePadding>
        <ListItemButton sx={{ pl: 4 }} onClick={handleEndSession}>
          <ListItemIcon>
            <LogoutOutlined color='primary' width={20} height={20} />
          </ListItemIcon>
          <ListItemText
            primary='Cerrar sesión'
            primaryTypographyProps={{
              color: theme.palette.common.black,
              fontWeight: "bold",
            }}
          />
        </ListItemButton>
      </List>
    </StyledContainer>
  );
};

export default NavigationMenu;
