import { UploadFile } from "@mui/icons-material";
import { Box, Typography, Button, Grid, Stack, useMediaQuery, Theme } from "@mui/material";
import PencilPlusOutlineIcon from "mdi-react/PencilPlusOutlineIcon";
import { FC, useEffect, memo } from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as PaperArrowIcon } from "@/assets/icons/paper-arrow-icon.svg";
import { ReactComponent as ParticipantsIcon } from "@/assets/icons/participants-icon.svg";
import DocumentOption from "@/components/DocumentOption";
import FileLoadInput from "@/components/FileLoadInput";
import { Permissions } from "@/helpers/permissions";
import { useSession, useDocument } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { useLazyGetSignatureCreditsByOwnerIdQuery } from "@/service";
import { EDocumentTypes } from "@/state/Document/slice.types";

import {
  containerSx,
  buttonSx,
} from "./DocumentCreation.styles";
import { SignatureCreditBar } from "../SignatureCreditBar";
import { SocialFooter } from "../SocialFooter";

const DocumentCreation: FC = () => {
  // eslint-disable-next-line
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const [getSignatureCreditsByOwnerId] = useLazyGetSignatureCreditsByOwnerIdQuery();

  const { createNewDocumentType } = useDocument();
  const { canUse, sessionSignatureCreditsSelectorSt } = useSession();

  useEffect(() => {
    getSignatureCreditsByOwnerId();
  }, []);

  const handleUploadFileDocumentType = () => {
    createNewDocumentType(EDocumentTypes.RENTING);
  };

  return (
    <AuthorizedRoute allowedPermission={Permissions.MANAGE_USER_DOCUMENTS}>
      <Box sx={containerSx} data-tesid='automatic-procedures-component'>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12}>
            <Typography variant='h4' align='center' color='primary'>
              Bienvenido <br /> a firma electrónica
            </Typography>
            <Typography variant='h6' align='center' color='primary'>
              Genera documentos listos para firmar
            </Typography>
          </Grid>
          {(sessionSignatureCreditsSelectorSt) && (
            <Grid item xs={12} md={8} lg={7}>
              <SignatureCreditBar />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-evenly" spacing={2}>
              <DocumentOption
                step={1}
                id={EDocumentTypes.BUYING_PROMISE}
                icon={<PaperArrowIcon width={isDesktop ? 100 : 80} height={isDesktop ? 100 : 80} />}
                text="Sube tu documento"
              />
              <DocumentOption
                step={2}
                id={EDocumentTypes.NONE}
                icon={<ParticipantsIcon width={isDesktop ? 100 : 80} height={isDesktop ? 100 : 80} />}
                text="Agrega participantes"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Stack spacing={2}>
              {canUse(Permissions.UPLOAD_USER_DOCUMENT_FILE) && (
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<UploadFile />}
                  component="label"
                  onClick={handleUploadFileDocumentType}
                  fullWidth
                  sx={buttonSx}
                >
                  Subir archivo
                  <FileLoadInput />
                </Button>
              )}
              {canUse(Permissions.PURCHASE_USER_SIGNATURE_CREDITS) && (
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<PencilPlusOutlineIcon />}
                  fullWidth
                  component={NavLink}
                  to="/dashboard/v1/signature-credits/purchase-credits"
                  sx={buttonSx}
                >
                  Comprar créditos de firmas
                </Button>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' align='center' fontWeight='bold' sx={{ display: 'none' }}>
              O adjunta tu propio documento
            </Typography>
            <SocialFooter />
          </Grid>
        </Grid>
      </Box>
    </AuthorizedRoute>
  );
};

export default memo(DocumentCreation, () => {
  return false;
});
