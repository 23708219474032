import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Typography, Box, IconButton, Grid } from '@mui/material';
import { FC } from 'react';

import { iconButtonSx } from './SocialFooter.styles';

const SocialFooter: FC = () => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <Typography variant="body1" fontWeight="bold" color="primary" align="center">
          Gracias por usar nuestros servicios
        </Typography>
        <Typography component="p" variant="caption" fontWeight="bold" align="center">
          Para más información, comunícate con nuestro equipo de soporte al cliente:
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Box display="flex" justifyContent="space-evenly">
          <IconButton
            aria-label="whatsapp"
            size="large"
            sx={iconButtonSx}
            onClick={() => window.open(
              "https://api.whatsapp.com/send?phone=5215639550122",
              "_blank",
              "noopener,noreferrer"
            )}
          >
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            aria-label="whatsapp"
            size="large"
            sx={iconButtonSx}
            onClick={() => (window.location.href = "tel:+5215639550122")}
          >
            <PhoneIcon />
          </IconButton>
          <IconButton
            aria-label="whatsapp"
            size="large"
            sx={iconButtonSx}
            onClick={() => (window.location.href = "mailto:diego@firmavirtual.legal")}
          >
            <MailOutlinedIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SocialFooter;