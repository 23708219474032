import { SxProps, Theme, Chip, emphasize, styled } from '@mui/material';

export const boxContaineSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "10px",
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
});

export const titleSx: SxProps<Theme> = (theme) => ({
  color: theme.palette.primary.main,
  mb: 2,
});

export const backButtonSx: SxProps<Theme> = (theme) => ({
  color: theme.palette.primary.main,
  border: `solid 1px ${theme.palette.primary.light}`,
  mr: 3,
});

export const toolbarNavSx: SxProps = () => ({
  display: 'flex',
  flexDirection: 'row',
  alignItem: {
    xs: 'flex-start',
    md: 'center'
  },
});

export const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: theme.spacing(3),
  boxShadow: theme.shadows[1],
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover, &:focus': {
    backgroundColor: emphasize(theme.palette.grey[100], 0.06),
  },
  '&:active': {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.common.white,
  },
})) as typeof Chip;