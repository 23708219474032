import { SxProps, Theme } from "@mui/material";


export const containerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  py: theme.spacing(2),
  px: 0,
  boxSizing: "border-box",
  borderRadius: '10px',
});

export const pdfContainerSx: SxProps = () => ({
  maxHeight: '700px',
  overflow: 'auto',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
});

export const actionButtonIconSx: SxProps<Theme> = (theme) => ({
  fill: theme.palette.atmosphere?.main
});