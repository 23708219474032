import { Box, BoxProps, List, ListProps, styled, SxProps, Theme } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(() => ({
  height: "100%",
}));

export const StyledSignersContainer = styled(Box)<BoxProps>(() => ({
  flex: 1,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

export const StyledSignersTitleContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: `0 ${theme.spacing(6)}`,
  }),
);

export const StyledSignersListContainer = styled(List)<ListProps>(() => ({
  width: "100%",
}));


export const containerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  boxSizing: "border-box",
  borderRadius: '10px',
  height: '100%',
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const buttonSx: SxProps = () => ({
  textTransform: 'capitalize',
  fontWeight: 'bold'
});

export const buttonStackSx: SxProps<Theme> = (theme) => ({
  mt: theme.spacing(2)
});

export const loadingContainerSx: SxProps<Theme> = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  boxSizing: "border-box",
  borderRadius: '10px',
});