import { Button, Typography, InputAdornment } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { animated, useSpring } from "@react-spring/web";
import { Formik, Field, ErrorMessage } from "formik";
import { TextField } from "formik-mui";
import { DatePicker } from "formik-mui-x-date-pickers";
import { DateTime } from "luxon";
import React from "react";
import { useNavigate } from "react-router-dom";

import FieldErrorMessage from "@/components/FieldErrorMessage";
import useDocument from "@/hooks/useDocument";
import { EDocumentCreationSteps } from "@/state/Document/slice.types";

import { validationSchema } from "./DocumentCreationForm.schema";
import {
  StyledContainer,
  StyledFormContainer,
  StyledFormFieldContainer,
} from "./DocumentCreationForm.styles";
import { IAutoProcedureFormProps } from "./DocumentCreationForm.types";

const DocumentCreationForm: React.FC<IAutoProcedureFormProps> = ({
  onClickSubmit,
}) => {
  const navigation = useNavigate();
  const { createNewDocument, setDocumentStep } = useDocument();
  const spring = useSpring({
    from: { opacity: 0, display: "none", transform: "translate3d(100%,0,0)" },
    to: { opacity: 1, display: "flex", transform: "translate3d(0,0,0)" },
  });
  const AnimatedContainer = animated(StyledContainer);

  return (
    <AnimatedContainer style={spring}>
      <Typography variant='h5' align='center' color='primary'>
        Formulario para generar documentos
      </Typography>
      <Formik
        initialValues={{
          selected_date: DateTime.now(),
          contract_duration: "00/00",
          deal_amount: "0",
          address: "",
          postal_code: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          createNewDocument({
            documentOrderInfo: {
              address: values.address,
              dealAmount: values.deal_amount,
              documentDuration: values.contract_duration,
              postalCode: values.postal_code,
              selectedDate: values.selected_date.toJSDate(),
            },
          });
          setSubmitting(false);
          setDocumentStep(EDocumentCreationSteps.SET_SIGNERS);
          navigation("/dashboard/v1/create-document/signers");
          onClickSubmit && onClickSubmit();
        }}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <StyledFormContainer>
                <StyledFormFieldContainer>
                  <Field
                    component={DatePicker}
                    name='selected_date'
                    type='text'
                    label='Seleccione fecha'
                    fullWidth
                  />
                  <ErrorMessage
                    name='selected_date'
                    component={props => <FieldErrorMessage {...props} />}
                  />
                </StyledFormFieldContainer>
                <Field
                  component={TextField}
                  name='contract_duration'
                  type='text'
                  label='Duración del contrato'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>MES/DíA</InputAdornment>
                    ),
                  }}
                />
                <Field
                  component={TextField}
                  name='deal_amount'
                  type='text'
                  label='Selecciona monto pactado'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>MXN</InputAdornment>
                    ),
                  }}
                />
                <Field
                  component={TextField}
                  name='address'
                  type='text'
                  label='Ingresa dirección'
                  fullWidth
                />
                <Field
                  component={TextField}
                  name='postal_code'
                  type='text'
                  label='Ingresa código postal'
                  fullWidth
                />
                <Button
                  variant='contained'
                  fullWidth
                  size='large'
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  <Typography
                    variant='body1'
                    align='center'
                    fontWeight='bold'
                    textTransform='capitalize'
                  >
                    Siguiente
                  </Typography>
                </Button>
              </StyledFormContainer>
            </LocalizationProvider>
          );
        }}
      </Formik>
    </AnimatedContainer>
  );
};

export default DocumentCreationForm;
