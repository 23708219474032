import * as yup from "yup";

import { IUser } from "@/state/User/slice.types";

const phoneRegExp = /^(\+52)?\s?\d{10}$/;
const rfcRegex = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/;

export const createValidationSchema = yup.object({
  rfc: yup
    .string()
    .matches(rfcRegex, { message: "RFC inválido" })
    .required("Este campo es requerido"),
  first_name: yup
    .string()
    .min(2, "El nombre debe contener al menos 2 caractertes")
    .max(255, "El nombre no puede contener mas de 255 caracteres")
    .required("Por favor introduce tus nombres"),
  last_name: yup
    .string()
    .min(2, "El apellido paterno debe contener al menos 2 caractertes")
    .max(255, "El apellido paterno no puede contener mas de 255 caracteres")
    .required("Por favor introduce tu apellido paterno"),
  mother_last_name: yup
    .string()
    .min(2, "El apellido materno debe contener al menos 2 caractertes")
    .max(255, "El apellido materno no puede contener mas de 255 caracteres")
    .required("Por favor introduce tu apellido materno"),
  phone: yup
    .string()
    .matches(phoneRegExp, { message: "Número telefónico no es válido" }),
    // .required("Por favor introduce tu número telefónico"),
  email: yup
    .string()
    .email("El correo no es válido")
    .required("Por favor introduce tu correo electrónico"),
  role_id: yup
    .number()
    .min(1)
    .required("Por favor selecciona un rol"),
  password: yup
    .string()
    .min(7, "La contraseña debe contener al menos 7 caractertes")
    .max(255, "La contraseña no puede contener más de 255 caractertes")
    .required("Por favor introduce una contraseña"),
});

export const updateValidationSchema = yup.object({
  rfc: yup
    .string()
    .matches(rfcRegex, { message: "RFC inválido" })
    .required("Este campo es requerido"),
  first_name: yup
    .string()
    .min(2, "El nombre debe contener al menos 2 caractertes")
    .max(255, "El nombre no puede contener mas de 255 caracteres")
    .required("Por favor introduce tus nombres"),
  last_name: yup
    .string()
    .min(2, "El apellido paterno debe contener al menos 2 caractertes")
    .max(255, "El apellido paterno no puede contener mas de 255 caracteres")
    .required("Por favor introduce tu apellido paterno"),
  mother_last_name: yup
    .string()
    .min(2, "El apellido materno debe contener al menos 2 caractertes")
    .max(255, "El apellido materno no puede contener mas de 255 caracteres")
    .required("Por favor introduce tu apellido materno"),
  phone: yup
    .string()
    .matches(phoneRegExp, { message: "Número telefónico no es válido" }),
    // .required("Por favor introduce tu número telefónico"),
  email: yup
    .string()
    .email("El correo no es válido")
    .required("Por favor introduce tu correo electrónico"),
  role_id: yup
    .number()
    .min(1)
    .required("Por favor selecciona un rol"),
});


export const initialValues: IUser = {
  rfc: '',
  first_name: '',
  last_name: '',
  mother_last_name: '',
  email: '',
  password: '',
  phone: '',
  role_id: 0
}