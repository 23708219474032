import * as yup from "yup";

import { ISignatureInventory } from "@/state/SignatureInventory/slice.types";

export const updateValidationSchema = yup.object({
  quantity: yup
    .number()
    .min(1)
    .required("Por favor selecciona una cantidad de créditos."),
});


export const initialValues: ISignatureInventory = {
  mode: '',
  quantity: '',
}