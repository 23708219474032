import { DateTime } from "luxon";
import * as yup from "yup";

const dateRegex = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])$/;

export const validationSchema = yup.object({
  selected_date: yup
    .date()
    .typeError(
      `La fecha debe ser mayor a ${DateTime.local().toFormat("dd LLL yyyy", {
        locale: "es",
      })}`,
    )
    .min(
      DateTime.now(),
      `La fecha mínima para generar el contrado es ${DateTime.local().toFormat(
        "dd LLL yyyy",
      )}`,
    )
    .required("Selecciona una fecha válida"),
  contract_duration: yup
    .string()
    .matches(dateRegex, "Ingresa una fecha válida en formato MM/DD")
    .length(
      5,
      "La duración del contrato debe ser de 5 caracteres en formato MM/DD",
    )
    .required("Ingresa la duración del contrato"),
  deal_amount: yup
    .string()
    .required("Ingresa el monto pactado")
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "El monto pactado debe ser un número válido con hasta dos decimales",
    )
    .test(
      "is-within-range",
      "El monto pactado debe ser mínimo 0 y máximo 999999999",
      value => {
        const number = parseFloat(value);
        return !isNaN(number) && number >= 0 && number <= 999999999;
      },
    ),
  address: yup.string().required("Ingresa tu dirección").trim(),
  postal_code: yup.string().required("Ingresa tu código postal").trim(),
});
