import { ArrowBack, Home, BadgeOutlined, PeopleOutline } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Breadcrumbs,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';

import { Permissions } from "@/helpers/permissions";
import { useLoader } from '@/hooks';
import AuthorizedRoute from '@/routes/AuthorizedRoute';
import { useLazyGetUserByIdQuery } from '@/service';
import { IUser } from '@/state/Session/slice.types';

import { boxContainerSx, StyledBreadcrumb, backButtonSx, listSx, titleSx } from './UserDetails.styles';

const UserDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser | undefined>();
  const [getUserById, { data, isLoading }] = useLazyGetUserByIdQuery();
  const { setLoader } = useLoader();

  useEffect(() => {
    getUserById(id);
  }, [id]);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      setUser(data.data as IUser);
    }
  }, [data]);

  const goBack = () => {
    navigate('/dashboard/v1/admin/manage-users/list')
  };

  return (
    <AuthorizedRoute allowedPermission={Permissions.VIEW_USER}>
      <Box sx={boxContainerSx} data-testid="user-details-component">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <IconButton
                aria-label="go back"
                sx={backButtonSx}
                onClick={goBack}
              >
                <ArrowBack />
              </IconButton>
              <Link component={NavLink} to="/dashboard/v1/admin/manage-users/list" variant="subtitle1">Volver atrás</Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="body1" sx={{ mr: 2 }}>Te encuentras aquí:</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  label="Gestión de usuarios"
                  icon={<Home fontSize="small" />}
                  disabled
                />
                <StyledBreadcrumb
                  label="Todos los usuarios"
                  icon={<PeopleOutline fontSize="small" />}
                  disabled
                />
                <StyledBreadcrumb
                  label="Detalles del usuario"
                  icon={<BadgeOutlined fontSize="small" />}
                />
              </Breadcrumbs>
            </Box>
          </Grid>
          {(user) && (
            <Grid item xs={12}>
              <List
                aria-labelledby="user-details-list"
                sx={listSx}
                subheader={
                  <ListSubheader id="user-details-list">
                    <Typography variant="h5" sx={titleSx}>
                      {`${user.first_name} ${user.last_name} ${user.mother_last_name}`}
                    </Typography>
                  </ListSubheader>
                }
              >
                <ListItem>
                  <ListItemText
                    primary="RFC"
                    primaryTypographyProps={{
                      fontWeight: 'bold'
                    }}
                    secondary={user.rfc}
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                  <ListItemText
                    primary="Correo electrónico"
                    primaryTypographyProps={{
                      fontWeight: 'bold'
                    }}
                    secondary={user.email}
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                  <ListItemText
                    primary="Teléfono"
                    primaryTypographyProps={{
                      fontWeight: 'bold'
                    }}
                    secondary={user.phone}
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
                <ListItem>
                  <ListItemText
                    primary="Rol"
                    primaryTypographyProps={{
                      fontWeight: 'bold'
                    }}
                    secondary={user.role.name}
                  />
                </ListItem>
              </List>
            </Grid>
          )}
        </Grid>
      </Box>
    </AuthorizedRoute>
  );
};

export default UserDetails;