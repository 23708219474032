import { BoxProps } from "@mui/material";
import React from "react";

import AvatarContainer from "@/components/AvatarContainer";
import { useSession } from "@/hooks";

import { StyledContainer } from "./Header.styles";

const Header: React.FC<BoxProps> = () => {
  const { sessionUserSt } = useSession();

  return (
    <StyledContainer data-testid='header-dashboard-component'>
      <AvatarContainer
        userName={sessionUserSt?.first_name}
        userRole={sessionUserSt?.role.name ?? ""}
      />
    </StyledContainer>
  );
};

export default Header;
