import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Permissions } from "@/helpers/permissions";
import { useLoader, useSession } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { useUpdateProfileMutation } from "@/service";
import { theme } from "@/theme";

import { validationSchema } from "./UpdateUser.schema";
import { StyledContainer } from "./UpdateUser.styles";

const UpdateUser: React.FC = () => {
  const { sessionUserSt, canUse } = useSession();
  const navigation = useNavigate();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { setLoader } = useLoader();

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <AuthorizedRoute allowedPermission={Permissions.VIEW_USER_PROFILE}>
      <StyledContainer item data-testid='update-user-info-component'>
        <Typography
          variant='h5'
          align='center'
          color='primary'
          mb={theme.spacing(6)}
        >
          Actualizar información de usuario
        </Typography>
        <Box width={{ xs: "100%", md: "70%", lg: "50%" }}>
          <Formik
            initialValues={{
              rfc: sessionUserSt.rfc,
              first_name: sessionUserSt.first_name,
              last_name: sessionUserSt.last_name,
              mother_last_name: sessionUserSt.mother_last_name,
              phone: sessionUserSt.phone,
              email: sessionUserSt.email,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              updateProfile({
                rfc: values.rfc,
                first_name: values.first_name,
                last_name: values.last_name,
                mother_last_name: values.mother_last_name,
                phone: values.phone,
                email: values.email,
                role_id: sessionUserSt.role.id,
              });

              setSubmitting(false);
            }}
          >
            {({ submitForm, isSubmitting }) => {
              return (
                <Stack spacing={4}>
                  <Stack spacing={3}>
                    <Field
                      component={TextField}
                      label="Nombre completo"
                      name="first_name"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      label="Apellido paterno"
                      name="last_name"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      label="Apellido materno"
                      name="mother_last_name"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      label="RFC"
                      name="rfc"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      label="Número de teléfono"
                      name="phone"
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      label="Correo electrónico"
                      name="email"
                      fullWidth
                    />
                  </Stack>
                  {canUse(Permissions.UPDATE_USER_PROFILE) && (
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={submitForm}
                      disabled={isSubmitting}
                    >
                      Actualizar
                    </Button>
                  )}
                  <Button
                    variant="text"
                    size="medium"
                    onClick={() => navigation('/dashboard/v1/admin')}
                    startIcon={<ArrowBack />}
                  >
                    Atrás
                  </Button>
                </Stack>
              );
            }}
          </Formik>
        </Box>
      </StyledContainer>
    </AuthorizedRoute>
  );
};

export default UpdateUser;
