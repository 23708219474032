import { useDispatch, useSelector } from "react-redux";

import { signatureInventorySelector } from "@/state/SignatureInventory/selectors";
import {
  setSignatureInventory as setSignatureInventoryAction
} from "@/state/SignatureInventory/slice";
import { ISignatureInventory } from "@/state/SignatureInventory/slice.types";


const useSession = () => {
  const dispatch = useDispatch();
  const signatureInventorySt = useSelector(signatureInventorySelector);

  const setSignatureInventory = (items: ISignatureInventory[]) => dispatch(setSignatureInventoryAction(items));

  return {
    signatureInventorySt,
    setSignatureInventory,
  };
};

export default useSession;
