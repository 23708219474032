import { Box, Typography, useTheme } from '@mui/material';
import TableLargeRemoveIcon from 'mdi-react/TableLargeRemoveIcon';
import { FC } from 'react';

import { containerSx } from './DataGridEmpty.styles';

const DataGridEmpty: FC = () => {
  const theme = useTheme();

  return (
    <Box sx={containerSx}>
      <TableLargeRemoveIcon size={60} color={theme.palette.atmosphere?.main} />
      <Typography variant="body1" align="center">El listado está vacío.</Typography>
    </Box>
  );
};

export default DataGridEmpty;