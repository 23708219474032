import { SxProps, Theme } from "@mui/material";


export const containerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // padding: theme.spacing(5),
  height: '100%',
});

export const iconSx: SxProps<Theme> = (theme) => ({
  fill: theme.palette.atmosphere?.main,
  width: 60,
  height: 60,
  mb: 2
});