import { HomeOutlined, PeopleOutline, AddBox } from '@mui/icons-material';
import { Grid, Typography, Box, Breadcrumbs, Button, Stack } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { StyledBreadcrumb, titleSx, buttonSx, toolbarNavSx } from "./UsersList.styles";

const UsersListToolbar: FC = () => {

  return (
    <GridToolbarContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="bold" align="center" sx={titleSx}>
            Gestión de usuarios
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={toolbarNavSx}>
            <Typography variant="body1" sx={{ mr: 2 }}>Te encuentras aquí:</Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <StyledBreadcrumb
                label="Gestión de usuarios"
                icon={<HomeOutlined fontSize="small" />}
                disabled
              />
              <StyledBreadcrumb
                label="Todos los usuarios"
                icon={<PeopleOutline fontSize="small" />}
              />
            </Breadcrumbs>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              component={NavLink}
              to="/dashboard/v1/admin/manage-users/create"
              variant="outlined"
              startIcon={<AddBox />}
              sx={buttonSx}
            >
              Agregar nuevo
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default UsersListToolbar;