import { Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Document } from "@/components/UserDocumentStatus/UserDocumentStatus.types";
import { theme } from "@/theme";

import { StyledPaperContainer } from "./DocumentsOverview.styles";
import {
  IDocumentsOverviewProps,
  IReviewProps,
} from "./DocumentsOverview.types";

const DocumentsOverview: React.FC<IDocumentsOverviewProps> = ({
  documents,
}) => {
  const [overview, setOverview] = useState<IReviewProps[] | null>(null);
  const navigation = useNavigate();

  useEffect(() => {
    if (documents?.length) {
      const final: IReviewProps[] = [
        {
          count: 0,
          details: "Documentos en total",
          color: theme.palette.secondary.main,
          onClick: () => navigation("/dashboard/v1/review-documents/status"),
        },
        {
          count: 0,
          details: "Documentos por pagar",
          color: theme.palette.atmosphere?.main ?? "",
          onClick: () =>
            navigation("/dashboard/v1/review-documents-payment/status"),
        },
        {
          count: 0,
          details: "Documentos por firmar",
          color: theme.palette.autumn?.light ?? "",
          onClick: () =>
            navigation("/dashboard/v1/review-documents-signature/status"),
        },
        {
          count: 0,
          details: "Documentos entregados",
          color: theme.palette.grass?.main ?? "",
          onClick: () =>
            navigation("/dashboard/v1/review-documents-delivery/status"),
        },
      ];

      documents.forEach((doc: Document) => {
        switch (doc.status) {
          // case "CREATED":
          //   final[0].count += 1;
          //   break;
          case "SIGNED_PENDING":
            final[2].count += 1;
            break;
          case "SIGNED":
            final[3].count += 1;
            break;
          default:
            final[0].count = documents?.length;
            break;
        }

        switch (doc.payment_status) {
          case "PENDING":
            final[1].count += 1;
            break;
        }
      });

      setOverview(final);
    }
  }, [documents]);

  return (
    <Grid container spacing={2} data-testid='documents-overview-component'>
      {overview &&
        overview.map((overview: IReviewProps, index: number) => (
          <Grid item xs={12} md={6} lg={3} key={`${index}_${overview.details}`}>
            <StyledPaperContainer
              variant='elevation'
              elevation={0}
              onClick={() => overview.onClick && overview.onClick()}
              color={overview.color}
            >
              <Typography variant='h4' color='white'>
                {overview.count}
              </Typography>
              <Typography variant='subtitle1' color='white' fontWeight='bold'>
                {overview.details}
              </Typography>
            </StyledPaperContainer>
          </Grid>
        ))}
    </Grid>
  );
};

export default DocumentsOverview;
