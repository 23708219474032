import { alpha, createTheme } from "@mui/material/styles";

import { EThemeCustomization, EColors } from "./theme.types";

export let theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h3",
          body1: "p",
          body2: "span",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontWeight: "bold",
          textTransform: "capitalize",
        },
        text: {
          fontWeight: "bold",
          textTransform: "capitalize",
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: "bolder",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#004f9f",
      light: "#00adea",
      dark: "#003c77",
    },
    secondary: {
      main: "#ff326e",
      light: "#f86262",
      dark: "#cf3966",
    },
    success: {
      main: "#5cc858",
    },
    error: {
      main: "#cf3966",
    },
    info: {
      main: "#ffb676",
    },
    warning: {
      main: "#f86262",
    },
  },
  shape: {
    borderRadius: EThemeCustomization.SPACING_BASE,
  },
  shadows: [
    `none`,
    `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)`,
    `0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%)`,
    `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`,
    `0px 5px 6px -3px rgb(0 0 0 / 20%), 0px 9px 12px 1px rgb(0 0 0 / 14%), 0px 3px 16px 2px rgb(0 0 0 / 12%)`,
    `0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)`,
    `0px 6px 7px -4px rgb(0 0 0 / 20%), 0px 11px 15px 1px rgb(0 0 0 / 14%), 0px 4px 20px 3px rgb(0 0 0 / 12%)`,
    `0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)`,
    `0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 13px 19px 2px rgb(0 0 0 / 14%), 0px 5px 24px 4px rgb(0 0 0 / 12%)`,
    `0px 7px 9px -4px rgb(0 0 0 / 20%), 0px 14px 21px 2px rgb(0 0 0 / 14%), 0px 5px 26px 4px rgb(0 0 0 / 12%)`,
    `0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%)`,
    `0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)`,
    `0px 8px 11px -5px rgb(0 0 0 / 20%), 0px 17px 26px 2px rgb(0 0 0 / 14%), 0px 6px 32px 5px rgb(0 0 0 / 12%)`,
    `0px 9px 11px -5px rgb(0 0 0 / 20%), 0px 18px 28px 2px rgb(0 0 0 / 14%), 0px 7px 34px 6px rgb(0 0 0 / 12%)`,
    `0px 9px 12px -6px rgb(0 0 0 / 20%), 0px 19px 29px 2px rgb(0 0 0 / 14%), 0px 7px 36px 6px rgb(0 0 0 / 12%)`,
    `0px 10px 13px -6px rgb(0 0 0 / 20%), 0px 20px 31px 3px rgb(0 0 0 / 14%), 0px 8px 38px 7px rgb(0 0 0 / 12%)`,
    `0px 10px 13px -6px rgb(0 0 0 / 20%), 0px 21px 33px 3px rgb(0 0 0 / 14%), 0px 8px 40px 7px rgb(0 0 0 / 12%)`,
    `0px 10px 14px -6px rgb(0 0 0 / 20%), 0px 22px 35px 3px rgb(0 0 0 / 14%), 0px 8px 42px 7px rgb(0 0 0 / 12%)`,
    `0px 11px 14px -7px rgb(0 0 0 / 20%), 0px 23px 36px 3px rgb(0 0 0 / 14%), 0px 9px 44px 8px rgb(0 0 0 / 12%)`,
    `0px 11px 15px -7px ${alpha("#004f9f", 0.1)}, 0px 24px 38px 3px ${alpha(
      "#004f9f",
      0,
    )}, 0px 9px 46px 8px ${alpha("#004f9f", 0)}`,
  ],
  spacing: EThemeCustomization.SPACING_BASE,
});

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h3",
          body1: "p",
          body2: "span",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: `${EColors.ATMOSPHERE}`,
          "&:hover": {
            backgroundColor: `${theme.palette.atmosphere?.dark}`,
          },
        },
      },
    },
  },
  palette: {
    autumn: theme.palette.augmentColor({
      color: {
        main: EColors.AUTUMN,
      },
      name: "autumn",
    }),
    grass: theme.palette.augmentColor({
      color: {
        main: EColors.GRASS,
      },
      name: "grass",
    }),
    pavement: theme.palette.augmentColor({
      color: {
        main: EColors.PAVEMENT,
      },
      name: "pavement",
    }),
    pimp: theme.palette.augmentColor({
      color: {
        main: EColors.PIMP,
      },
      name: "pimp",
    }),
    almostwhite: theme.palette.augmentColor({
      color: {
        main: EColors.ALMOSTWHITE,
      },
      name: "almostwhite",
    }),
    atmosphere: theme.palette.augmentColor({
      color: {
        main: EColors.ATMOSPHERE,
      },
      name: "atmosphere",
    }),
  },
});
