import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import DocumentDetailData from "@/components/DocumentDetailData";
import {
  Document,
  EBiometricValidation,
  Signer,
  File,
} from "@/components/DocumentDetailData/DocumentDetailData.types";
import DocumentSignerItem from "@/components/DocumentSignerItem";
import { Permissions } from "@/helpers/permissions";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { useLazyGetOneDocumentQuery } from "@/service";

const DocumentDetail: React.FC = () => {
  const params = useParams();
  const [getDocument, { data, isSuccess }] = useLazyGetOneDocumentQuery();
  const [updateDocument, setUpdateDocument] = useState<Document | null>(null);
  const [updateSigners, setUpdateSigners] = useState<Signer[] | null>(null);
  const [updateFile, setUpdateFile] = useState<File | null>(null);

  useEffect(() => {
    getDocument(params.id);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setUpdateDocument(data.data);
      setUpdateSigners(data.data.signatures);
      setUpdateFile(data.data.file);
    }
  }, [isSuccess]);

  const isVerified = (signer: Signer): boolean => {
    if (signer.biometric_validation) {
      return signer.biometric_validation.current_step === EBiometricValidation.FINISH;
    }

    return false;
  };

  return (
    <AuthorizedRoute allowedPermission={Permissions.VIEW_USER_DOCUMENT}>
      <Box data-testid='document-detail-page'>
        {data && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={9}>
              {(updateDocument && updateSigners && updateFile) && (
                <DocumentDetailData
                  document={updateDocument}
                  file={updateFile}
                  signers={updateSigners}
                />
              )}
            </Grid>
            <Grid item xs={12} md={5} lg={3}>
              <Stack spacing={2}>
                {data.data.signatures.map((signer: Signer, index: number) => {
                  const verified = isVerified(signer);
                  return (
                    <DocumentSignerItem
                      key={`${signer.signature_code}_${index}`}
                      signer={signer}
                      verified={verified}
                      documentId={data.data.id}
                      documentUuid={data.data.uuid}
                      signerId={signer.id}
                      paymentStatus={updateDocument?.payment_status}
                    />
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
    </AuthorizedRoute>
  );
};

export default DocumentDetail;
