import {
  Box,
  Grid,
  TextField,
  Button,
  Alert
} from '@mui/material';
import { Formik } from 'formik';
import { FC, ChangeEvent, useEffect } from 'react';

import { useLoader, useMessager } from '@/hooks';
import { useUpdateSignatureInventoryMutation, useLazyGetAllInventoryQuery } from '@/service';
import { ISignatureInventory } from '@/state/SignatureInventory/slice.types';

import { initialValues, updateValidationSchema } from './SignatureInventoryForm.schema';

interface UpdateSignatureInventoryFormProps {
  mode: string;
  item: ISignatureInventory;
}

const UpdateSignatureInventoryForm: FC<UpdateSignatureInventoryFormProps> = ({ mode, item }) => {
  const { setMessage } = useMessager();
  const { setLoader } = useLoader();

  const [updateSignatureInventory, { isLoading, isSuccess }] = useUpdateSignatureInventoryMutation();
  const [getAllInventory] = useLazyGetAllInventoryQuery();

  const handleOnClose = () => {
    getAllInventory();
  };

  useEffect(() => {
    setLoader(isLoading);
    if (isSuccess && !isLoading) {
      setMessage({
        title: "Inventario actualizado",
        body: (
          <Alert severity="success">
            Se ha actaulizado el inventario de firmas correctamente.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleOnClose,
        type: "modal",
      })
    }
  }, [isLoading, isSuccess])

  const onSubmit = (values: ISignatureInventory) => {
    updateSignatureInventory({
      id: item.id,
      quantity: values.quantity,
      mode
    });
  };

  return (
    <Box sx={{ pt: 2 }}>
      <Grid container>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={updateValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ handleSubmit, values, errors, setFieldValue, isValid, setFieldError }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      id="quantity"
                      name="quantity"
                      value={values.quantity}
                      label="Cantidad de firmas"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('quantity', event.target.value === '' ? 0 : Number(event.target.value));
                        if (Number(event.target.value) > Number(item.quantity) && mode === 'decrement') {
                          setFieldError('quantity', 'El número de firmas que intentas remover excede el número actual de firmas disponibles.')
                        } else {
                          setFieldError('quantity', undefined)
                        }
                      }}
                      variant="outlined"
                      error={Boolean(errors.quantity)}
                      helperText={errors.quantity && errors.quantity}
                      onBlur={() => {
                        if (Number(values.quantity) < 0) {
                          setFieldValue('quantity', 0);
                        }
                      }}
                      inputProps={{
                        min: 1,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoading || !isValid}
                      >
                        Aceptar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateSignatureInventoryForm;