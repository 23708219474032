import { useDispatch, useSelector } from "react-redux";

import { messageSelector } from "@/state/Messager/selectors";
import {
  setMessage as setMessageAction,
  clearMessage as clearMessageAction,
} from "@/state/Messager/slice";
import { IMessagerState } from "@/state/Messager/slice.types";

const useMessager = () => {
  const dispatch = useDispatch();
  const messageSt = useSelector(messageSelector);

  const setMessage = (props: IMessagerState) =>
    dispatch(setMessageAction(props));

  const clearMessage = () => dispatch(clearMessageAction());

  return {
    messageSt,
    setMessage,
    clearMessage,
  };
};

export default useMessager;
