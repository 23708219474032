import { Typography, Box, Badge } from "@mui/material";
import { FC } from "react";

import {
  badgeSx,
  containerSx,
  iconContainerSx,
  typographySx,
} from "./DocumentOption.styles";
import { IAutoOptionProps } from "./DocumentOption.types";

const DocumentOption: FC<IAutoOptionProps> = ({
  step,
  icon,
  text,
}) => {


  return (
    <Box sx={containerSx} data-testid='document-option-component'>
      <Badge sx={badgeSx} badgeContent={step}>
        <Box sx={iconContainerSx}>
          {icon}
        </Box>
      </Badge>
      <Typography component="p" variant="body2" color="primary" align="center" sx={typographySx}>
        {text}
      </Typography>
    </Box>
  );
};

export default DocumentOption;
