import {
  PersonOutlined,
  DriveFileRenameOutline,
  HomeOutlined,
  ManageAccountsOutlined,
  DescriptionOutlined
} from "@mui/icons-material";

import { Permissions } from "@/helpers/permissions";

import { INavigationMenuMap } from "./NavigationMenu.types";

export const userMenuItems: INavigationMenuMap[] = [
  {
    text: "Inicio",
    id: "home",
    icon: <HomeOutlined color='primary' width={20} height={20} />,
    subMenu: [],
    link: "create-document",
    permission: Permissions.VIEW_HOME,
  },
  {
    text: "Gestión de documentos",
    id: "manage-formalities",
    icon: <DescriptionOutlined color='primary' width={20} height={20} />,
    link: null,
    permission: Permissions.MANAGE_USER_DOCUMENTS,
    subMenu: [
      {
        text: "Crear documentos",
        id: "create-formalities",
        to: "create-document",
        permission: Permissions.CREATE_USER_DOCUMENT
      },
      {
        text: "Gestión de pagos",
        id: "manage-payments",
        to: "review-documents-payment/status",
        permission: Permissions.LIST_USER_DOCUMENTS
      },
      {
        text: "Gestión de firmas",
        id: "manage-signatures",
        to: "review-documents-signature/status",
        permission: Permissions.LIST_USER_DOCUMENTS
      },
      {
        text: "Gestión de entregas",
        id: "manage-deliveries",
        to: "review-documents-delivery/status",
        permission: Permissions.LIST_USER_DOCUMENTS
      },
      {
        text: "Gestión de documentos",
        id: "manage-documents",
        to: "review-documents/status",
        permission: Permissions.LIST_USER_DOCUMENTS
      },
    ],
  },
  {
    text: "Gestión de créditos de firmas",
    id: "manage-signature-credits",
    icon: <DriveFileRenameOutline color='primary' width={20} height={20} />,
    link: null,
    permission: Permissions.MANAGE_USER_SIGNATURE_CREDITS,
    subMenu: [
      {
        text: "Comprar créditos",
        id: "purchase-credits",
        to: "signature-credits/purchase-credits",
        permission: Permissions.PURCHASE_USER_SIGNATURE_CREDITS
      },
      {
        text: "Gestión de pagos",
        id: "list-purchases",
        to: "signature-credits/list-purchases",
        permission: Permissions.PAY_USER_SIGNATURE_CREDITS
      },
    ],
  },
  {
    text: "Gestión de perfil",
    id: "user-settings",
    icon: <ManageAccountsOutlined color='primary' width={20} height={20} />,
    link: null,
    permission: Permissions.VIEW_USER_PROFILE,
    subMenu: [],
  },
];

export const adminMenuItems: INavigationMenuMap[] = [
  {
    text: "Inicio",
    id: "home",
    icon: <HomeOutlined color='primary' width={20} height={20} />,
    subMenu: [],
    link: "admin",
    permission: Permissions.VIEW_HOME,
  },
  {
    text: "Gestión de usuarios",
    id: "manage-users",
    icon: <PersonOutlined color='primary' width={20} height={20} />,
    link: null,
    permission: Permissions.MANAGE_USERS,
    subMenu: [
      {
        text: "Crear Usuario",
        id: "manage-users-create",
        to: "admin/manage-users/create",
        permission: Permissions.CREATE_USER
      },
      {
        text: "Ver usuarios",
        id: "manage-users-list",
        to: "admin/manage-users/list",
        permission: Permissions.LIST_USERS
      },
    ],
  },
  {
    text: "Gestión de inventario de firmas",
    id: "manage-signature-inventory",
    icon: <DriveFileRenameOutline color='primary' width={20} height={20} />,
    link: null,
    permission: Permissions.MANAGE_SIGNATURE_INVENTORY,
    subMenu: [
      {
        text: "Ver inventario",
        id: "manage-signature-inventory-list",
        to: "admin/manage-signature-inventory/list",
        permission: Permissions.LIST_SIGNATURE_INVENTORY
      },
    ],
  },
  {
    text: "Gestión de perfil",
    id: "user-settings",
    icon: <ManageAccountsOutlined color='primary' width={20} height={20} />,
    link: null,
    permission: Permissions.VIEW_USER_PROFILE,
    subMenu: [],
  },
];