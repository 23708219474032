import { useDispatch, useSelector } from "react-redux";

import { usersSelector } from "@/state/User/selectors";
import {
  setUsers as setUsersAction,
} from "@/state/User/slice";
import { IUser } from "@/state/User/slice.types";


const useUser = () => {
  const dispatch = useDispatch();
  const usersSt = useSelector(usersSelector);

  const setUsers = (users: IUser[]) => dispatch(setUsersAction(users));

  return {
    usersSt,
    setUsers
  };
};

export default useUser;
