import {
  DescriptionOutlined,
  DownloadOutlined,
  PersonOutlined,
  VisibilityOutlined,
  MailOutlined,
  SmartphoneOutlined,
  TagOutlined,
  GroupsOutlined,
  RuleOutlined,
  ReceiptOutlined
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
  Tooltip,
  useMediaQuery,
  Theme
} from "@mui/material";
import { FC, useState, useEffect } from "react";

import { useMessager, useSession } from "@/hooks";
import { useLazyDownloadDocumentQuery } from "@/service";
import { EDocumentSignerRoles } from "@/state/Document/slice.types";
import { theme } from "@/theme";

import DocumentDetailBreadcrumbs from "./DocumentDetailBreadcrumbs";
import { containerSx, paymentSummarySx, secondaryTextSx, listItemSx } from "./DocumentDetailData.styles";
import {
  IDocumentDetailDataProps,
  IListItemHeaderProps,
  Signer,
  DocumentPaymentStatusMap,
  DocumentStatusMap,
} from "./DocumentDetailData.types";
import PDFViewer from "../PDFViewer";

const ListHeader: FC<IListItemHeaderProps> = ({ itemId, primaryText, secondaryText }) => {
  return (
    <List>
      <ListItem sx={{ px: 0 }}>
        <ListItemAvatar>
          <Avatar variant="rounded" sx={{ bgcolor: theme.palette.primary.light }}>
            <Typography variant='h6' color='white'>
              {itemId}
            </Typography>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={primaryText}
          primaryTypographyProps={{ fontWeight: "bold" }}
          secondary={secondaryText}
        />
      </ListItem>
    </List>
  );
};

const DocumentDetailData: FC<IDocumentDetailDataProps> = ({ file, document, signers }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const { sessionUserSt } = useSession();
  const { setMessage } = useMessager();
  const [downloadDocument] = useLazyDownloadDocumentQuery();

  const [payer, setPayer] = useState<Signer | undefined>(undefined);

  useEffect(() => {
    setPayer(signers.find((signer: Signer) => {
      return (signer.role === EDocumentSignerRoles.PAYER || signer.role === EDocumentSignerRoles.SIGNER_PAYER)
    }))
  }, [signers])

  const downloadPdf = (base64String: string, fileName = file.name) => {
    const pdfBytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);
    const link = window.document.createElement("a");
    link.href = blobUrl;
    link.download = fileName;
    window.document.body.appendChild(link);
    link.click();

    window.document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  const downloadDocumentPDF = async (id: number) => {
    const res = await downloadDocument(id);
    if (res.isSuccess) {
      downloadPdf(res.data.data.file);
    }
  };

  const viewDocumentAsPDF = async (id: number) => {
    const res = await downloadDocument(id);
    if (res.isSuccess) {
      if (res.data.data.file) {
        setMessage({
          title: "Documento cargado",
          buttonCloseTitle: "Cerrar",
          type: "modal",
          open: true,
          body: (
            <PDFViewer
              base64={`data:application/pdf;base64,${res.data.data.file}`}
            />
          ),
        });
      }
    }
  };

  return (
    <Box sx={containerSx} data-testid='document-detail-data-component'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DocumentDetailBreadcrumbs document={document} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <ListHeader
                itemId={1}
                primaryText='Datos personales del solicitante'
                secondaryText='Mis datos personales'
              />
              <List dense>
                <ListItem sx={listItemSx}>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Nombre"
                    secondary={`${sessionUserSt.first_name} ${sessionUserSt.last_name} ${sessionUserSt.mother_last_name}`}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
                <ListItem sx={listItemSx}>
                  <ListItemAvatar>
                    <Avatar>
                      <MailOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="E-mail"
                    secondary={sessionUserSt.email}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
                <ListItem sx={listItemSx}>
                  <ListItemAvatar>
                    <Avatar>
                      <SmartphoneOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Teléfono"
                    secondary={sessionUserSt.phone}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} lg={6}>
              <ListHeader
                itemId={2}
                primaryText='Datos del documento'
                secondaryText='Datos del documento a realizar'
              />
              <List dense>
                <ListItem sx={listItemSx}>
                  <ListItemAvatar>
                    <Avatar>
                      <TagOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="ID del documento"
                    secondary={document.document_code}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
                <ListItem sx={listItemSx}>
                  <ListItemAvatar>
                    <Avatar>
                      <GroupsOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Participantes"
                    secondary={signers.length}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
                <ListItem sx={listItemSx}>
                  <ListItemAvatar>
                    <Avatar>
                      <RuleOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Estado del documento"
                    secondary={DocumentStatusMap[document.status]}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} lg={6}>
              <ListHeader itemId={3} primaryText='Acciones del documento' secondaryText='Ver o Descargar el documento' />
              <List dense>
                <ListItem
                  sx={listItemSx}
                  secondaryAction={
                    <Stack direction="row">
                      <Tooltip title="Ver Archivo">
                        <IconButton
                          onClick={() => viewDocumentAsPDF(document.id)}
                          color="atmosphere"
                          size={isDesktop ? 'medium' : 'small'}
                        >
                          <VisibilityOutlined fontSize={isDesktop ? 'medium' : 'small'} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Descargar">
                        <IconButton
                          onClick={() => downloadDocumentPDF(document.id)}
                          color="atmosphere"
                          size={isDesktop ? 'medium' : 'small'}
                        >
                          <DownloadOutlined fontSize={isDesktop ? 'medium' : 'small'} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <DescriptionOutlined />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Archivo"
                    secondary={file.name}
                    primaryTypographyProps={{
                      fontWeight: 'bold',
                      variant: 'body2',
                    }}
                    secondaryTypographyProps={{
                      // noWrap: true,
                      sx: secondaryTextSx
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            {(payer) && (
              <>
                <Grid item xs={12} lg={6}>
                  <ListHeader
                    itemId={4}
                    primaryText='Resumen del pedido'
                    secondaryText='Total a pagar'
                  />
                  <List dense>
                    <ListItem sx={listItemSx}>
                      <ListItemAvatar>
                        <Avatar>
                          <ReceiptOutlined />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Estado del pago de las firmas"
                        secondary={DocumentPaymentStatusMap[document.payment_status]}
                        primaryTypographyProps={{
                          fontWeight: 'bold',
                          variant: 'body2',
                        }}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Paper square={false} sx={paymentSummarySx}>
                    <List dense>
                      <ListItem
                        secondaryAction={
                          <ListItemText
                            primary={`$${payer.total_pay.toFixed(2)}`}
                            primaryTypographyProps={{
                              fontWeight: 'bold',
                            }}
                          />
                        }
                      >
                        <ListItemText
                          primary="FIRMA DE DOCUMENTO"
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                          }}
                        />
                      </ListItem>
                      <ListItem
                        secondaryAction={
                          <ListItemText
                            primary={`(16%) $${payer.iva_pay.toFixed(2)}`}
                            primaryTypographyProps={{
                              fontWeight: 'bold',
                            }}
                          />
                        }
                        divider
                      >
                        <ListItemText
                          primary="IVA"
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                          }}
                        />
                      </ListItem>
                      <ListItem
                        secondaryAction={
                          <ListItemText
                            primary={`$${payer.payment.toFixed(2)}`}
                            primaryTypographyProps={{
                              fontWeight: 'bold',
                            }}
                          />
                        }
                      >
                        <ListItemText
                          primary="TOTAL"
                          primaryTypographyProps={{
                            fontWeight: 'bold',
                          }}
                        />
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DocumentDetailData;