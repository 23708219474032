import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISignerValidationState } from "./slice.types";

const initialState: ISignerValidationState = {
  isValid: false,
  validationStep: 0,
  validationIdDocument: {
    front: "",
    back: "",
  },
  validationVideoStatement: "",
  error: false,
};

export const signerValidationSlice = createSlice({
  initialState,
  name: "signer-validation",
  reducers: {
    setValidationStatus: (
      state: ISignerValidationState,
      { payload }: PayloadAction<ISignerValidationState>,
    ) => {
      state.isValid = payload.isValid;
    },
    setValidationStep: (
      state: ISignerValidationState,
      { payload }: PayloadAction<number>,
    ) => {
      state.validationStep = payload;
    },
    setValidationIdDocument: (
      state: ISignerValidationState,
      {
        payload,
      }: PayloadAction<ISignerValidationState["validationIdDocument"]>,
    ) => {
      state.validationIdDocument = payload;
    },
    setValidationVideoStatement: (
      state: ISignerValidationState,
      {
        payload,
      }: PayloadAction<ISignerValidationState["validationVideoStatement"]>,
    ) => {
      state.validationVideoStatement = payload;
    },
    setError: (
      state: ISignerValidationState,
      { payload }: PayloadAction<ISignerValidationState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const {
  setValidationStatus,
  setValidationStep,
  setValidationIdDocument,
  setValidationVideoStatement,
  setError,
} = signerValidationSlice.actions;
