import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IRoleState, IRole } from "./slice.types";

const initialState: IRoleState = {
  roles: [],
  error: false,
};

export const roleSlice = createSlice({
  initialState,
  name: "roles",
  reducers: {
    setRoles: (
      state: IRoleState,
      { payload }: PayloadAction<IRole[]>,
    ) => {
      state.roles = payload;
    },
    setError: (
      state: IRoleState,
      { payload }: PayloadAction<IRoleState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setRoles, setError } = roleSlice.actions;
