import { Box, BoxProps, styled } from "@mui/material";
import { Form, FormikFormProps } from "formik";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(2),
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  gap: theme.spacing(7),
  flex: 1,
}));

export const StyledFormContainer = styled(Form)<FormikFormProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    width: "70%",
  }),
);

export const StyledFormFieldContainer = styled(Box)<BoxProps>(() => ({
  width: "100%",
  "& > *": {
    width: "100%",
  },
}));
