import { IServiceErrorSatus } from "@/service/service.types";

export enum IRoleEnum {
  USER = 'USER',
  ADMIN = 'ADMIN'
}

export interface IRole {
  id: number;
  name: string;
  description: string;
  is_active: boolean;
}

export interface ISignatureCredit {
  id: number;
  remaining_quantity: number;
  consumed_quantity: number;
  created_at: string;
}

export interface IUser {
  id: number;
  uuid: string;
  rfc: string;
  first_name: string;
  last_name: string;
  mother_last_name: string | null;
  phone: string;
  email: string;
  username: string;
  role: IRole;
  signature_credit: ISignatureCredit | null;
  created_at: string;
}

export interface ISessionState {
  user?: IUser;
  signature_credit: ISignatureCredit | null;
  token: string | null;
  role?: string;
  permissions: string[];
  password_reset: boolean;
  error?: IServiceErrorSatus["error"];
}
