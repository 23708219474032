import {
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import SignatureInventoryListActions from "./SignatureInventoryListActions";

const SourceName: { [x: string]: string } = {
  PSC_WORLD: 'PSC World',
  OTHER: 'OTRO'
}

export const colDefs: GridColDef[] = [
  {
    field: "actions",
    headerName: "Acciones",
    width: 200,
    renderCell: ({ row }) => (<SignatureInventoryListActions row={row} />),
  },
  {
    field: "quantity",
    headerName: "Cantidad restante de firmas",
    width: 200,
    renderCell: ({ value }) => <Typography variant="body1">{value}</Typography>,
  },
  {
    field: "source",
    headerName: "Nombre del proveedor",
    width: 300,
    renderCell: ({ value }) => <Typography variant="body1">{SourceName[value]}</Typography>,
  },
];