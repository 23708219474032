import { ArrowBack } from "@mui/icons-material";
import { Button, TextField, Typography, Box, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLoader, useMessager } from "@/hooks";
import { useResetPasswordMutation } from "@/service";

import { validationSchema, initialValues, ResetPasswordValues } from "./ResetPassword.schema";
import { submitButtonSx } from "./ResetPassword.styles";

const ResetPassword: React.FC = () => {
  const navigation = useNavigate();
  const { setLoader } = useLoader();
  const { setMessage } = useMessager();
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();

  useEffect(() => {
    setLoader(isLoading);
    if (isSuccess) {
      setMessage({
        title: "Revise su correo electrónico",
        body: "Hemos enviado un correo electrónico con las indicaciones para restablecer su contraseña",
        open: true,
        type: "modal",
        buttonCloseTitle: "Entendido",
        onClose: () => navigation(-1),
      });
    }
  }, [isLoading, isSuccess]);

  const onSubmit = (values: ResetPasswordValues) => {
    resetPassword(values);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6' align='center'>
            Ingrese el correo electrónico asociado a la cuenta
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik
            data-testid='reset-password-page'
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, handleChange, values, errors, touched, dirty }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      label="Correo electrónico"
                      placeholder="Escribe..."
                      variant="outlined"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      size="large"
                      type="submit"
                      disabled={isLoading || !dirty}
                      sx={submitButtonSx}
                    >
                      Recuperar contraseña
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="text"
                      fullWidth
                      size="large"
                      disabled={isLoading}
                      startIcon={<ArrowBack />}
                      onClick={() => navigation(-1)}
                    >
                      Atras
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResetPassword;
