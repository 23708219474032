import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUserState, IUser } from "./slice.types";

const initialState: IUserState = {
  users: [],
  currentUser: undefined,
  error: false,
};

export const userSlice = createSlice({
  initialState,
  name: "user",
  reducers: {
    setUsers: (
      state: IUserState,
      { payload }: PayloadAction<IUser[]>,
    ) => {
      state.users = payload;
    },
    setCurrentUserUser: (
      state: IUserState,
      { payload }: PayloadAction<IUser>,
    ) => {
      state.currentUser = payload;
    },
    setError: (
      state: IUserState,
      { payload }: PayloadAction<IUserState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setUsers, setError } = userSlice.actions;
