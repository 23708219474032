import { ExpandMore, ChevronLeft } from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { ReactNode, MouseEvent } from "react";

import { INavigationMeniItemProps } from "./NavigationMenuItem.types";

const NavigationMenuItem = React.forwardRef<
  HTMLDivElement,
  INavigationMeniItemProps
>(({ icon, primaryText, expanded, showExpandIcon, onClick }, ref) => {
  return (
    <ListItemButton
      ref={ref}
      onClick={(e: MouseEvent<HTMLDivElement>) => onClick(e)}
      data-testid='navigation-menu-item-component'
    >
      {icon && <ListItemIcon>{icon as ReactNode}</ListItemIcon>}
      <ListItemText
        primary={primaryText}
        inset={false}
        primaryTypographyProps={{ fontWeight: "bold" }}
      />
      {showExpandIcon &&
        (expanded ? (
          <ExpandMore color='primary' />
        ) : (
          <ChevronLeft color='primary' />
        ))}
    </ListItemButton>
  );
});

NavigationMenuItem.displayName = "NavigationMenuItem";

export default NavigationMenuItem;
