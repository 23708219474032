import React from "react";
import { Outlet } from "react-router-dom";

const PrivateRoute: React.FC = () => {
  // const { navigationSt } = useNavigation();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!navigationSt.activeSession) {
  //     navigate("/session/login");
  //   }
  // }, []);

  return <Outlet />;
};

export default PrivateRoute;
