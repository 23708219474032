import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISignatureInventoryState, ISignatureInventory } from "./slice.types";

const initialState: ISignatureInventoryState = {
  signature_inventory: [],
  error: false,
};

export const signatureInventorySlice = createSlice({
  initialState,
  name: "signature_inventory",
  reducers: {
    setSignatureInventory: (
      state: ISignatureInventoryState,
      { payload }: PayloadAction<ISignatureInventory[]>,
    ) => {
      state.signature_inventory = payload;
    },
    setError: (
      state: ISignatureInventoryState,
      { payload }: PayloadAction<ISignatureInventoryState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setSignatureInventory, setError } = signatureInventorySlice.actions;
