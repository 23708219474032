import { SxProps, Theme } from "@mui/material";

export const containerSx: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  display: 'grid',
  borderRadius: '10px',
  width: '100%',
  minHeight: {
    xs: 360,
    md: 293
  }
});

export const dataGridSx: SxProps<Theme> = (theme) => ({
  borderWidth: 0,
  p: theme.spacing(2)
});