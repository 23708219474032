import * as yup from "yup";

const rfcRegex = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/i;
export interface ParticipantValues {
  first_name: string;
  last_name: string;
  mother_last_name: string;
  email: string;
  rfc: string;
  role: string;
  portion: number;
}

export const validationSchema = yup.object({
  first_name: yup
    .string()
    .min(2, "El nombre debe contener al menos 2 caractertes")
    .max(256, "El nombre no puede contener mas de 255 caracteres")
    .trim()
    .required("Por favor introduce los nombres del participante"),
  last_name: yup
    .string()
    .min(2, "El apellido paterno debe contener al menos 2 caractertes")
    .max(256, "El apellido paterno no puede contener mas de 255 caracteres")
    .trim()
    .required("Por favor introduce el apellido paterno del participante"),
  mother_last_name: yup
    .string()
    .min(2, "El apellido materno debe contener al menos 2 caractertes")
    .max(256, "El apellido materno no puede contener mas de 255 caracteres")
    .trim()
    .required("Por favor introduce el apellido materno del participante"),
  role: yup
    .string()
    .required("Por favor selecciona el rol del participante"),
  email: yup
    .string()
    .email("El correo no es válido")
    .required("Por favor introduce el correo electrónico del participante")
    .trim(),
  rfc: yup
    .string()
    .matches(rfcRegex, { message: "RFC inválido" })
    .required("Por favor introduce el RFC del participante"),
  portion: yup
    .number()
    .oneOf([0, 50, 100])
    .required("Selecciona un porcentaje a pagar"),
});


export const initialValues: ParticipantValues = {
  first_name: '',
  last_name: '',
  mother_last_name: '',
  email: '',
  rfc: '',
  role: '',
  portion: 100,
}