import { Box, BoxProps, styled, SxProps, Theme } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // height: "100%",
  height: '700px',
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  borderRadius: theme.shape.borderRadius
}));


export const containerSx: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(2)
});