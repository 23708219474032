import {
  Box,
  Button,
  Typography,
  Alert,
  CircularProgress,
  Stack,
  Grid,
} from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DisplaySignersList from "@/components/DisplaySignersList";
import PDFSignersPage from "@/components/PDFSignersPage";
import SignersModal from "@/components/SignersModal";
import { Permissions } from "@/helpers/permissions";
import { useSession, useDocument } from "@/hooks";
import { useCreateDocumentMutation } from "@/service";
import {
  EDocumentCreationSteps,
  EDocumentSignerRoles,
  IDocumentSigner,
} from "@/state/Document/slice.types";

import {
  StyledContainer,
  containerSx,
  buttonSx,
  loadingContainerSx,
  buttonStackSx
} from "./Signers.styles";
import { ISignersProps } from "./Signers.types";

const Signers: React.FC<ISignersProps> = ({
  onClickPrev,
  onClickNext,
  createPDFDocument,
}) => {
  const navigation = useNavigate();
  const [openModalForm, setOpenModalForm] = useState<boolean>(false);
  const [participantSelected, setParticipantSelected] = useState<number>(-1);
  const [payerSelected, setPayerSelected] = useState<boolean>(false);
  const [signersSelected, setSignersSelected] = useState<boolean>(false);
  const [paymentPercentage, setPaymentPercentage] = useState<number>(0);
  const [hasRemainingCredits, setHasRemainingCredits] = useState<boolean>(false);

  const {
    documentSt,
    documentSignersSt,
    documentBase64St,
    setDocumentStep,
    documentWithSignersPage,
  } = useDocument();
  const { sessionUserSt, sessionSignatureCreditsSelectorSt } = useSession();
  const [createDocument, { isLoading, isSuccess }] = useCreateDocumentMutation();
  const { canUse } = useSession();

  const spring = useSpring({
    from: { opacity: 0, display: "none", transform: "translate3d(100%,0,0)" },
    to: { opacity: 1, display: "flex", transform: "translate3d(0,0,0)" },
  });
  const currentParticipant = documentSignersSt
    ? documentSignersSt.length + 1
    : 1;


  const AnimatedContainer = animated(StyledContainer);

  useEffect(() => {
    setPaymentPercentage(
      documentSignersSt.reduce((sum, item) => sum + item.portion, 0),
    );
  }, [documentSignersSt]);

  useEffect(() => {
    const hasPayers = documentSignersSt.some(
      (sg: IDocumentSigner) =>
        sg.role === EDocumentSignerRoles.PAYER ||
        sg.role === EDocumentSignerRoles.SIGNER_PAYER,
    );

    const hasSigners = documentSignersSt.some(
      (sg: IDocumentSigner) =>
        sg.role === EDocumentSignerRoles.SIGNER ||
        sg.role === EDocumentSignerRoles.SIGNER_PAYER,
    );

    setPayerSelected(hasPayers);
    setSignersSelected(hasSigners);
  }, [documentSignersSt]);

  useEffect(() => {
    if (sessionSignatureCreditsSelectorSt !== null && sessionSignatureCreditsSelectorSt?.remaining_quantity > 0) {
      setHasRemainingCredits(true);
    }
  }, [hasRemainingCredits, sessionUserSt])

  useEffect(() => {
    isSuccess && navigation("/dashboard/v1/upload-document/document-is-ready");
  }, [isSuccess]);

  const handleModalDisplay = () => {
    setParticipantSelected(-1);
    setOpenModalForm(prev => !prev);
  };

  const handlePrev = () => {
    setDocumentStep(EDocumentCreationSteps.SET_TYPE);
    navigation("/dashboard/v1/create-document");
    onClickPrev && onClickPrev();
  };

  const handleNext = () => {
    createPDFDocument && setDocumentBase64();
    onClickNext && onClickNext();
    setDocumentStep(EDocumentCreationSteps.SET_NOTIFICATION);
  };

  const setDocumentBase64 = async () => {
    if (documentBase64St) {
      const listSigners = documentSignersSt.filter(
        (sg: IDocumentSigner) =>
          sg.role === EDocumentSignerRoles.SIGNER_PAYER ||
          sg.role === EDocumentSignerRoles.SIGNER,
      );

      const result = await documentWithSignersPage({
        base64: documentBase64St,
        signersComponentPage: <PDFSignersPage signers={listSigners} />,
      });

      const resultData = result?.split("data:application/pdf;base64,")[1];

      if (result) {
        createDocument({
          owner_id: sessionUserSt.id,
          owner_type: "NATURAL",
          // payment_service: 1,
          document_type_fee_id: documentSt.documentType,
          file: resultData,
          signer_count: listSigners.length,
          page_sign: documentSt.documentSigningPage,
          signers: documentSignersSt.map(
            (sg: IDocumentSigner) => ({
              rfc: sg.rfc,
              first_name: sg.first_name,
              last_name: sg.last_name,
              mother_last_name: sg.mother_last_name,
              email: sg.email,
              role: sg.role,
              signer_type: "NATURAL",
              portion: 100,
              // posX: signersPosition[index].PosX,
              // posY: signersPosition[index].PosY,
            }),
          ),
        });
      }
    }
  };

  return (
    <>
      <SignersModal
        open={openModalForm}
        participant={currentParticipant}
        onClose={handleModalDisplay}
        paymentPercentage={paymentPercentage}
        signer={documentSignersSt[participantSelected]}
      />
      <AnimatedContainer style={spring} data-testid='signers-component'>
        {!isLoading ? (
          <Box sx={containerSx}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" align="center" color="primary">
                  Formulario para generar documentos
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" fontWeight="bold">
                  Participantes
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DisplaySignersList
                  setSignerCallback={setParticipantSelected}
                  setEditSignerCallback={setOpenModalForm}
                />
                <Stack spacing={2}>
                  {(!payerSelected && !sessionSignatureCreditsSelectorSt && !hasRemainingCredits) && (
                    <Alert severity='warning'>
                      Debes elegir al menos un (1) pagador
                    </Alert>
                  )}
                  {!signersSelected && (
                    <Alert severity='warning'>
                      Debes elegir al menos un (1) firmante
                    </Alert>
                  )}
                  {/* {paymentPercentage !== 100 && (
                    <Alert severity='warning' sx={{ borderRadius: 0 }}>
                      Aún no has cubierto el 100% del pago
                    </Alert>
                  )} */}
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="center" spacing={2} sx={buttonStackSx}>
              <Button
                variant="contained"
                onClick={handlePrev}
                sx={buttonSx}
              >
                Volver
              </Button>
              {canUse(Permissions.CREATE_USER_DOCUMENT) && (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={
                    !Boolean(documentSignersSt.length) ||
                    (!payerSelected && !sessionSignatureCreditsSelectorSt) ||
                    !signersSelected ||
                    // paymentPercentage !== 100 ||
                    isLoading
                  }
                  sx={buttonSx}
                >
                  Iniciar proceso
                </Button>
              )}
            </Stack>
          </Box>
        ) : (
          <Box sx={loadingContainerSx}>
            <CircularProgress color='primary' />
          </Box>
        )}
      </AnimatedContainer>
    </>
  );
};

export default Signers;