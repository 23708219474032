import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILoaderState } from "./slice.types";

const initialState: ILoaderState = {
  open: false,
};

export const loaderSlice = createSlice({
  initialState,
  name: "loader",
  reducers: {
    setLoader: (state: ILoaderState, { payload }: PayloadAction<boolean>) => {
      state.open = payload;
    },
  },
});

export const { setLoader } = loaderSlice.actions;
