import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { useMessager, useLoader } from "@/hooks";
import {
  useDeleteDocumentMutation,
  useLazyGetAllDocumentsQuery,
} from "@/service";

import { colDefs } from "./UserDocumentStatus.map";
import { containerSx, dataGridSx } from "./UserDocumentStatus.styles";
import {
  IUserDocumentStatusDataGrid,
  IUserDocumentDataResponse,
  Document,
} from "./UserDocumentStatus.types";
// import CustomRow from "./UserDocumentStatusRow";
import CustomToolbar from "./UserDocumentStatusToolbar";
import DataGridEmpty from "../DataGridEmpty";

const UserDocumentStatus: React.FC = () => {
  const data = useOutletContext<QueryReturnValue>();
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState<IUserDocumentStatusDataGrid[] | null>(null);

  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const [deleteDocument, { isLoading, isSuccess }] =
    useDeleteDocumentMutation();
  const [getAll, { isLoading: isLoadingDocuments }] = useLazyGetAllDocumentsQuery();

  useEffect(() => {
    setLoader(isLoading);
    isSuccess && getAll();
  }, [isLoading, isSuccess]);

  useEffect(() => {
    setLoader(isLoadingDocuments);
  }, [isLoadingDocuments]);

  const openDeleteModal = (documentId: number) => {
    setMessage({
      title: "¿Deseas eliminar este documento?",
      body: "Ten en cuenta que habrás perdido todos los archivos relacionados con este documento, así como las validaciones de identidad y firmas de los participantes.",
      open: true,
      buttonCloseTitle: "No",
      type: "modal",
      extraButton: true,
      extraButtonTitle: "Si",
      handleExtraButtonClick: () => deleteDocument(documentId),
    });
  };

  const handleDocumentsData = (documents: IUserDocumentDataResponse) => {
    if (documents) {
      const docs = documents.data;
      const docsAsDataGrid: IUserDocumentStatusDataGrid[] = docs.map(
        (doc: Document) => ({
          actions: () => openDeleteModal(doc.id),
          uuid: doc.uuid,
          id: doc.id,
          date: doc.created_at,
          procedure: {
            documentType: "Contrato de arriendo",
            documentSignatureType: "Firma con certificación notarial",
          },
          status: doc.status,
          document_code: doc.document_code
          // created_at: doc.created_at,
          // signers_count: docs.length,
          // payment_status: doc.payment_status,
        }),
      );

      setRows(docsAsDataGrid);
    }
  };

  useEffect(() => {
    data && handleDocumentsData(data as IUserDocumentDataResponse);
  }, [data]);

  return (
    <Box sx={containerSx} data-testid='user-documents-status-component'>
      <DataGrid
        apiRef={apiRef}
        columns={colDefs}
        rows={rows ?? []}
        sx={dataGridSx}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        loading={isLoadingDocuments}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: DataGridEmpty
        }}
        localeText={{
          filterOperatorContains: "Contiene",
          filterOperatorEquals: "Igual",
          filterOperatorStartsWith: "Comienza con",
          filterOperatorEndsWith: "Termina con",
          filterOperatorIsEmpty: "Está vacío",
          filterOperatorIsNotEmpty: "No esta vacío",
          filterOperatorIsAnyOf: "Es cualquiera de",
          filterPanelOperator: "Criterio",
          filterPanelColumns: "Columnas",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Ingresa un valor",
          columnMenuSortAsc: "Ordenar ASC",
          columnMenuSortDesc: "Ordenar DESC",
          columnMenuHideColumn: "Ocultar columna",
          columnMenuManageColumns: "Gestionar columnas",
          columnMenuFilter: "Filtrar",
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
    </Box>
  );
};

export default UserDocumentStatus;
