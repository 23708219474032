import {
  Document as RendererDocument,
  Page as RendererPage,
} from "@react-pdf/renderer";

import { usePDFStyles } from "./PDFSignersPage.styles";
import { IPDFSignersPageProps } from "./PDFSignersPage.types";

// TODO: Habilitar componente para asignacion de firmas
// const SignerItem: React.FC<IPDFSignersView> = ({
//   fullName,
//   fatherLastname,
//   motherLastname,
//   RFC,
//   posX,
//   posY,
// }) => {
//   return (
//     <View
//       style={{
//         ...usePDFStyles.customPDFView,
//         top: posY,
//         left: posX,
//       }}
//     >
//       <Svg height='5' width='200'>
//         <Line
//           x1='0'
//           y1='0'
//           x2='100%'
//           y2='0'
//           strokeWidth={1}
//           stroke='rgb(0,0,0)'
//         />
//       </Svg>
//       <Text
//         style={usePDFStyles.customText}
//       >{`${fullName} ${fatherLastname} ${motherLastname}`}</Text>
//       <Text style={usePDFStyles.customText2}>RFC: {RFC}</Text>
//     </View>
//   );
// };

const PDFSignersPage: React.FC<IPDFSignersPageProps> = () => {
  return (
    <RendererDocument data-testid='pdf-signers-page-component'>
      <RendererPage size='A4' style={usePDFStyles.customPDFPage}>
        {/* TODO: Verificar posiciones de firma */}
        {/* {signers.map((signer, index) => (
          <SignerItem
            key={`${signer.RFC}_${index}`}
            RFC={signer.RFC}
            fullName={signer.fullName}
            fatherLastname={signer.fatherLastname}
            motherLastname={signer.motherLastname}
            posX={signersPosition[index].PosX}
            posY={signersPosition[index].PosY}
          />
        ))} */}
      </RendererPage>
    </RendererDocument>
  );
};

export default PDFSignersPage;
