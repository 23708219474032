import { VisibilityOffOutlined, VisibilityOutlined, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Alert,
  TextField
} from "@mui/material";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useMessager, useLoader } from "@/hooks";
import { useRegisterUserMutation } from "@/service";
import { IUser } from "@/state/User/slice.types";

import { validationSchema, initialValues } from "./SignUpForm.schema";
import { submitButtonSx } from "./SignUpForm.styles";

const SignUpForm: React.FC = () => {
  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const navigation = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [registerUser, { isLoading, isSuccess }] = useRegisterUserMutation();

  const handleClickShowPassword = () => setShowPassword(prev => !prev);

  const handleClose = () => {
    navigation("/session/login");
  };

  useEffect(() => {
    setLoader(isLoading);
    if (isSuccess && !isLoading) {
      setMessage({
        title: "Registro realizado con éxito",
        body: (
          <Alert severity="success">
            Se ha realizado el registro en la plataforma éxitosamente.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleClose,
        type: "modal",
      })
    }
  }, [isSuccess, isLoading]);

  const onSubmit = (values: IUser) => {
    registerUser(values)
  };

  return (
    <Box component="main">
      <Formik
        data-testid='signup-form-component'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleSubmit, handleChange, values, errors, touched, dirty }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="first_name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  label="Nombres"
                  placeholder="Escribe..."
                  variant="outlined"
                  error={Boolean(touched.first_name && errors.first_name)}
                  helperText={touched.first_name && errors.first_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="last_name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  label="Apellido paterno"
                  placeholder="Escribe..."
                  variant="outlined"
                  error={Boolean(touched.last_name && errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="mother_last_name"
                  name="mother_last_name"
                  value={values.mother_last_name}
                  onChange={handleChange}
                  label="Apellido materno"
                  placeholder="Escribe..."
                  variant="outlined"
                  error={Boolean(touched.mother_last_name && errors.mother_last_name)}
                  helperText={touched.mother_last_name && errors.mother_last_name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="rfc"
                  name="rfc"
                  value={values.rfc}
                  onChange={handleChange}
                  label="RFC"
                  placeholder="Escribe..."
                  variant="outlined"
                  error={Boolean(touched.rfc && errors.rfc)}
                  helperText={touched.rfc && errors.rfc}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  label="Correo electrónico"
                  placeholder="Escribe..."
                  variant="outlined"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  label="Teléfono"
                  placeholder="Escribe..."
                  variant="outlined"
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  label="Contraseña"
                  placeholder="Mínimo 8 caracteres"
                  variant="outlined"
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading || !dirty}
                  fullWidth
                  size="large"
                  sx={submitButtonSx}
                >
                  Registrarse
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  fullWidth
                  size="large"
                  disabled={isLoading}
                  startIcon={<ArrowBack />}
                  onClick={() => navigation(-1)}
                >
                  Atras
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default SignUpForm;
