import { Box, BoxProps, styled, SxProps, Theme } from "@mui/material";

const drawerWidth = 300;

export const StyledMenuContainer = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    flex: 3,
  },
  [theme.breakpoints.up("md")]: {
    flex: 2,
  },
}));

export const StyledMenuAndContentContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flex: 1,
  boxSizing: "border-box",
}));

export const StyledContentContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 9,
  padding: theme.spacing(2),
}));

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.almostwhite?.main,
  height: "100vh",
}));


export const appBarSx: SxProps<Theme> = (theme) => ({
  width: { sm: `calc(100% - ${drawerWidth}px)` },
  ml: { sm: `${drawerWidth}px` },
  backgroundColor: theme.palette.common.white
});

export const menuButtonSx: SxProps<Theme> = (theme) => ({
  ml: theme.spacing(1)
});

export const navSx: SxProps = () => ({
  width: {
    sm: drawerWidth
  },
  flexShrink: {
    sm: 0
  }
});

export const drawerSx: SxProps = () => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: drawerWidth
  }
});

export const mainSx: SxProps = () => ({
  flexGrow: 1,
  p: 3,
  width: {
    sm: `calc(100% - ${drawerWidth}px)`
  }
});

export const navigationMenuSx: SxProps<Theme> = (theme) => ({
  display: "flex",
  height: '100%',
  py: theme.spacing(2)
});