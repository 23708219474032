import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Alert
} from '@mui/material';
import { Formik } from 'formik';
import { FC, ChangeEvent, useEffect } from 'react';

import { useMessager, useLoader } from '@/hooks';
import { useCreateSignatureCreditMutation, useLazyGetAllUsersQuery } from '@/service';
import { ISignatureCredit } from '@/service/service.types';
import { IUser } from '@/state/User/slice.types';

import { initialValues, validationSchema } from './SignatureCreditForm.schema';
import { listSx } from './SignatureCreditForm.styles';

interface SignatureCreditFormProps {
  user: IUser;
}

const SignatureCreditForm: FC<SignatureCreditFormProps> = ({ user }) => {
  const { setMessage } = useMessager();
  const { setLoader } = useLoader();

  const [createSignatureCredit, { isLoading, isSuccess }] = useCreateSignatureCreditMutation();
  const [getAllUsers] = useLazyGetAllUsersQuery();

  const handleOnClose = () => {
    getAllUsers();
  };

  useEffect(() => {
    setLoader(isLoading);
    if (isSuccess && !isLoading) {
      setMessage({
        title: "Compra realizada con éxito",
        body: (
          <Alert severity="success">
            Se han asignado las firmas correctamente.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleOnClose,
        type: "modal",
      })
    }
  }, [isLoading, isSuccess])

  const onSubmit = (values: ISignatureCredit) => {
    createSignatureCredit({
      user_id: user.id,
      quantity: values.quantity
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List aria-labelledby="signature-credit-form-details" sx={listSx}>
            <ListItem sx={listSx}>
              <ListItemText
                primary="Nombre usuario:"
                primaryTypographyProps={{
                  fontWeight: 'bold'
                }}
                secondary={`${user.first_name} ${user.last_name} ${user.mother_last_name}`}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values, errors, touched, setFieldValue, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Typography variant="body1" sx={{ mr: 2 }}>Cantidad de créditos:</Typography>
                      <TextField
                        id="quantity"
                        name="quantity"
                        value={values.quantity}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue('quantity', event.target.value === '' ? 0 : Number(event.target.value))}
                        variant="outlined"
                        error={Boolean(touched.quantity && errors.quantity)}
                        helperText={touched.quantity && errors.quantity}
                        onBlur={() => {
                          if (values.quantity < 0) {
                            setFieldValue('quantity', 0);
                          } else if (values.quantity > 5000) {
                            setFieldValue('quantity', 5000);
                          }
                        }}
                        inputProps={{
                          min: 10,
                          max: 5000,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isLoading || !isValid}
                      >
                        Aceptar
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignatureCreditForm;