import { Grid } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const User: React.FC = () => {
  return (
    <Grid container>
      <Outlet />
    </Grid>
  );
};

export default User;
