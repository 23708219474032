import { Box, BoxProps, lighten, styled, SxProps, Theme } from "@mui/material";

interface ExtendedBox extends BoxProps {
  isselected: boolean;
}

export const StyledContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.common.white,
  boxSizing: "border-box",
  borderRadius: theme.shape.borderRadius,
  maxWidth: theme.spacing(30),
  gap: theme.spacing(3),
}));

export const StyledIconContainer = styled(Box)<ExtendedBox>(({
  theme,
  isselected,
}) => {
  const additional = () =>
    isselected
      ? {
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
        borderColor: theme.palette.primary.light,
      }
      : null;
  return {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...additional(),
  };
});

export const StyledInfoContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: theme.spacing(20),
}));

export const containerSx: SxProps = () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const iconContainerSx: SxProps<Theme> = (theme) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '10px',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(3),
});

export const badgeSx: SxProps<Theme> = (theme) => ({
  '& .MuiBadge-badge': {
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: 16,
    width: 36,
    height: 36,
    right: {
      xs: 10,
      md: 0,
    },
  }
})

export const typographySx: SxProps<Theme> = (theme) => ({
  fontWeight: 'bold',
  fontSize: 16,
  mt: theme.spacing(1)
});