import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Stack,
  useMediaQuery,
  Theme,
  IconButton
} from "@mui/material";
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import { FC, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useDocument } from "@/hooks";

import { containerSx, pdfContainerSx } from "./PDFViewer.styles";
import { IPDFViewerProps } from "./PDFViewer.types";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFViewer: FC<IPDFViewerProps> = ({ base64 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);
  const [pageWidth, setPageWidth] = useState<number>(0);
  const { setDocumentSignersPage } = useDocument();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    ref.current && setPageWidth(ref.current.offsetWidth);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setDocumentSignersPage(numPages + 1);
    setNumPages(numPages);
  };

  const onPageLoadSuccess = ({ view }: PDFPageProxy): void => {
    const originaWidth = view[2];
    let getScale = (pageWidth && originaWidth) ? pageWidth / originaWidth : 1;
    getScale = 0.98; // Set constant scale to display correctly the pdf
    setScale(getScale);
  };

  const handlePagination = (target: "prev" | "next"): void => {
    switch (target) {
      case "prev":
        setPage(prev => prev - 1);
        break;
      case "next":
        setPage(prev => prev + 1);
        break;
    }
  };

  return (
    <Box sx={containerSx}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={pdfContainerSx} ref={ref}>
            <Document file={base64} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                pageNumber={page}
                renderMode='canvas'
                renderTextLayer={false}
                renderAnnotationLayer={false}
                loading={<CircularProgress />}
                onLoadSuccess={onPageLoadSuccess}
                width={pageWidth}
                scale={scale}
              />
            </Document>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            {isDesktop ? (
              <Button
                variant='contained'
                onClick={() => handlePagination("prev")}
                disabled={page === 1}
              >
                Página anterior
              </Button>
            ) : (
              <IconButton
                edge="start"
                onClick={() => handlePagination("prev")}
                disabled={page === 1}
                color="atmosphere"
              >
                <ChevronLeftOutlined />
              </IconButton>
            )}
            <Typography variant='body2'>
              {page} de {numPages}
            </Typography>
            {isDesktop ? (
              <Button
                variant='contained'
                onClick={() => handlePagination("next")}
                disabled={page === numPages}
              >
                Página siguiente
              </Button>
            ) : (
              <IconButton
                edge="start"
                onClick={() => handlePagination("next")}
                disabled={page === numPages}
                color="atmosphere"
              >
                <ChevronRightOutlined />
              </IconButton>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PDFViewer;
