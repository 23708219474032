import { HomeOutlined, DriveFileRenameOutline, AddBox } from '@mui/icons-material';
import { Grid, Typography, Box, Breadcrumbs, Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { FC } from 'react';
import { NavLink } from "react-router-dom";

import { buttonSx, StyledBreadcrumb, titleSx, toolbarNavSx } from "./SignatureInventoryList.styles";

const SignatureInventoryListToolbar: FC= () => {

  return (
    <GridToolbarContainer>
      <Grid container mb={1}>
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight="bold" align="center" sx={titleSx}>
          Gestión de inventario de firmas
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={toolbarNavSx}>
            <Typography variant="body1" sx={{ mr: 2 }}>Te encuentras aquí:</Typography>
            <Breadcrumbs aria-label="breadcrumb">
              <StyledBreadcrumb
                label="Gestión de inventario de firmas"
                icon={<HomeOutlined fontSize="small" />}
                disabled
              />
              <StyledBreadcrumb
                label="Ver inventario"
                icon={<DriveFileRenameOutline fontSize="small" />}
              />
            </Breadcrumbs>
          </Box>
        </Grid>
        {false && (
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                component={NavLink}
                to="/dashboard/v1/admin/manage-users/create"
                variant="outlined"
                startIcon={<AddBox />}
                sx={buttonSx}
              >
                Agregar nuevo
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
};

export default SignatureInventoryListToolbar;