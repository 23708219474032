import { Box } from '@mui/material';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';


const Admin: FC = () => {
  return (
    <Box data-testid="admin-page">
      <Outlet />
    </Box>
  );
};

export default Admin;