import { ThemeProvider } from "@mui/material";
import { RenderOptions, render } from "@testing-library/react";
import React from "react";
import { Provider } from "react-redux";

import { store } from "@/store";
import { theme } from "@/theme";

const Providers: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Provider>
  );
};

const renderWithWrapper = (
  ui: React.ReactElement,
  options?: Omit<RenderOptions, "queries">,
) => render(ui, { wrapper: Providers, ...options });

export * from "@testing-library/react";

export { renderWithWrapper as render };
