import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/store";

const selfState = (state: RootState) => state.signer_validation;
export const signerValidationSelector = createSelector(
  selfState,
  state => state,
);
export const validationStepSelector = createSelector(
  selfState,
  state => state.validationStep,
);
