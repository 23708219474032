import { Box, Grid, useMediaQuery, Theme  } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import { ReactComponent as Logo } from "@/assets/logos/fv-logo-reg.svg";
import { theme } from "@/theme";

import { showroomContainerSx } from "./Sessions.styles";

const Sessions: React.FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  return (
    <Grid container data-testid="sessions-page">
      <Grid item xs={12} md={5}>
        <Box display="flex" flexDirection="column" justifyContent="center" height="100vh">
          <Box display="flex" justifyContent="center" mb={theme.spacing(10)}>
            <Logo height={40} />
          </Box>
          <Box mx={theme.spacing(5)}>
            <Outlet />
          </Box>
        </Box>
      </Grid>
      {(isDesktop) && (
        <Grid item xs={12} md={7} sx={showroomContainerSx} />
      )}
    </Grid>
  );
};

export default Sessions;
